import { map } from 'lodash'
import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../utils/style-utils'
import RichText from '../RichText'
import { CardSlice } from '../../../prismicio-types'

import { CardPadding } from './card-styles'

const RichTextCard: React.FC<{ slice: CardSlice }> = ({ slice }) => {
	const center = slice.primary.center_content
	return (
		<Inner center={center}>
			<TextContainer center={center}>
				<Text content={slice.primary.title} />
				<Text content={slice.primary.description} />
			</TextContainer>
			{slice.items.length ? (
				<ImagesContainer>
					{map(slice.items, (item, index) => {
						return item.image.url ? (
							<ImageWrapper key={`card-image-${index}`}>
								<Image
									src={item.image.url}
									alt='merki'
								/>
							</ImageWrapper>
						) : null
					})}
				</ImagesContainer>
			) : null}
		</Inner>
	)
}

export default RichTextCard

const Image = styled.img`
	max-height: 48px;

	${media.desktopHuge`
		max-height: 94px;
	`}

	${media.desktopXl`
		max-height: 94px;
	`}
`
const ImageWrapper = styled.div``

const TextContainer = styled.div<{ center?: boolean }>`
	display: contents;
	${(p) =>
		p.center &&
		css`
			display: flex;
			flex-direction: column;
			gap: var(--spacer-md);
		`}
`

const ImagesContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	gap: 24px;

	margin-top: var(--spacer-xs);
`

const Inner = styled.div<{ center }>`
	display: flex;
	height: 100%;
	width: 100%;
	align-items: center;
	flex-wrap: wrap;
	padding: ${CardPadding};
`

const Text = styled(RichText)`
	width: 100%;
	h1 {
		margin: 0px;
		max-width: 11ch;
		font-size: clamp(2.5rem, 4vw, 8.75rem);

		${media.tablet`
			max-width: 18ch;
			margin-bottom: var(--spacer-xs);
		`};
	}
	p {
		margin-top: 0px;
		max-width: 42ch;
		span {
			font-size: clamp(2.125rem, 2vw, 3.5rem);
		}

		${media.phone`
			span{
				display: block;
				margin-top: -1.8rem;
				font-size: 1.2rem;
			}
		`};
	}
`
