import { filter, map } from 'lodash'
import React, { useMemo } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import styled from 'styled-components'
import { LinkField } from '@prismicio/client'

import { media } from '../../utils/style-utils'
import { getLinkUrl } from '../../utils/ts-helpers'

interface IDropdownMenu {
	isOpen: boolean
	items: {
		label: string
		link: LinkField
		primary?: boolean
		isMobileNavbarItem: boolean
	}[]
}

const MENU_ANIMATIONS = {
	closed: {
		opacity: 0,
		x: -16,
		transition: {
			staggerChildren: 0.05,
			staggerDirection: 1,
			// when: 'afterChidlren',
			opacity: {
				duration: 0.2,
			},
		},
	},
	open: {
		opacity: 1,
		x: 0,
		transition: {
			staggerChildren: 0.07,
			when: 'beforeChildren',
			opacity: {
				duration: 0.2,
			},
		},
	},
}

const BUTTON_ANIMATIONS = {
	closed: {
		opacity: 0,
		x: -50,
		transition: {
			x: { stiffness: 1, velocity: -100 },
		},
	},
	open: {
		opacity: 1,
		x: 0,
		transition: {
			x: { stiffness: 1, velocity: -100 },
		},
	},
}

const MobileDropdownMenu: React.FC<IDropdownMenu> = ({ isOpen, items }) => {
	const filteredItems = useMemo(() => filter(items, (i) => i.isMobileNavbarItem !== true), [items])

	return (
		<MenuWrapper>
			<AnimatePresence>
				{isOpen && (
					<Menu
						animate={'open'}
						initial={'closed'}
						exit={'closed'}
						variants={MENU_ANIMATIONS}
					>
						{map(filteredItems, (item, index) => {
							return (
								<MenuButton
									key={`${item.link}-${index}`}
									variants={BUTTON_ANIMATIONS}
									whileHover={{ scale: 1.1 }}
									whileTap={{ scale: 0.95 }}
									href={getLinkUrl(item.link)}
								>
									{item.label}
								</MenuButton>
							)
						})}
					</Menu>
				)}
			</AnimatePresence>
		</MenuWrapper>
	)
}

export default MobileDropdownMenu

const MenuWrapper = styled.div`
	display: none;

	${media.tablet`
		display: unset;
	`}
`

const Menu = styled(motion.div)`
	display: flex;
	flex-direction: column;
	gap: var(--spacer-sm);
	width: 92vw;
	padding: var(--spacer-md);
	background: var(--blue-100);
	border-radius: 40px;
	z-index: 1; // * 1 because of the mobile dropdown animation
	box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 30.1471px 24.1177px rgba(0, 0, 0, 0.0456112),
		0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 4.5288px 3.62304px rgba(0, 0, 0, 0.0243888);

	${media.tablet`
		width: 88vw;
	`};
`

const MenuButton = styled(motion.a)`
	all: unset;
	font-family: var(--font-atyp-display);
	font-weight: 500;
	line-height: 1.5rem;
	font-size: 1rem;
	color: var(--deep-blue);
	cursor: pointer;

	user-select: none;
	transition: color var(--animation-transition);

	&:hover {
		color: var(--blue-400);
	}

	&:focus {
		outline: 0;
	}

	&:focus-visible {
		outline: 3px solid var(--deep-blue);
	}
`
