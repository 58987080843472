import { KvikaSVGProps } from './types'

const Hamburger = ({ fill = 'var(--blue-400)', size = 32 }: KvikaSVGProps) => {
	return (
		<svg
			width={size}
			height={size}
			fill={fill}
			viewBox='0 0 32 32'
			xmlns='http://www.w3.org/2000/svg'
		>
			<rect
				x={4}
				y={5}
				width={24}
				height={4}
				rx={2}
				fill={fill}
			/>
			<rect
				x={4}
				y={14}
				width={24}
				height={4}
				rx={2}
				fill={fill}
			/>
			<rect
				x={4}
				y={14}
				width={24}
				height={4}
				rx={2}
				fill={fill}
			/>
			<rect
				x={4}
				y={23}
				width={24}
				height={4}
				rx={2}
				fill={fill}
			/>
		</svg>
	)
}

export default Hamburger
