import { ReactNode } from 'react'
import styled from 'styled-components'

import NavBar from '../components/NavBar/NavBar'
import { AutoSpacer } from '../../slices/Spacer'
import Chat, { IChat } from '../components/Chat/Chat'
import { FooterDocument, HeaderNavigationDocument } from '../../prismicio-types'
import { useNotifications } from '../utils/use-notifications'

import Footer from './Footer'

type Props = {
	headerNavigation: HeaderNavigationDocument
	children?: ReactNode
	footer: FooterDocument
	logo_color?: string
	supportBubble: IChat
}

const Layout = ({ children, footer, headerNavigation, supportBubble }: Props) => {
	useNotifications()
	return (
		<PageWrapper>
			<AutoSpacer negative={false} />
			{headerNavigation && <NavBar navigation={headerNavigation} />}
			<ContentLayoutWrapper>{children}</ContentLayoutWrapper>
			<AutoSpacer negative={false} />
			{supportBubble && <Chat supportBubble={supportBubble} />}
			{footer && <Footer footer={footer} />}
		</PageWrapper>
	)
}

export default Layout

const PageWrapper = styled.div`
	min-height: 100vh;
	display: flex;
	flex-direction: column;
`

const ContentLayoutWrapper = styled.div`
	transition: all 320ms ease;
	height: 100vh;
	flex: 1;
	flex-direction: column;
	display: flex;
`
