import { LinkToMediaField } from '@prismicio/client'
import React from 'react'
import styled from 'styled-components'

import { getLinkUrl } from '../../utils/ts-helpers'

interface IVideoCard {
	media: LinkToMediaField
	animateOnHover?: boolean
}

const VideoCard: React.FC<IVideoCard> = ({ media, animateOnHover }) => {
	const playVideo = (e) => {
		e.target.play()
	}

	const stopVideo = (e) => {
		e.target.pause()
		e.target.currentTime = 0
	}

	return (
		<ImageWrapper>
			<Video
				onMouseOver={animateOnHover ? playVideo : undefined}
				onMouseOut={animateOnHover ? stopVideo : undefined}
				autoPlay={animateOnHover ? false : true}
				src={getLinkUrl(media)}
				loop
				muted
			/>
		</ImageWrapper>
	)
}

const Video = styled.video`
	object-fit: cover;
	width: 100%;
	height: 100%;
`

const ImageWrapper = styled.div`
	position: relative;
	height: 100%;
	width: 100%;
`

export default VideoCard
