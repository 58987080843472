import { useState, useEffect, useCallback } from 'react'
import { get } from 'lodash'
import { RichTextBlock } from 'prismic-reactjs'
import * as ReactHotToast from 'react-hot-toast'
import { RichTextField } from '@prismicio/client'

import Alert from '../../components/Alert/Alert'
import HeaderNotification from '../../components/HeaderNotification/HeaderNotification'

export interface INotification {
	title: string
	message: string | RichTextField | RichTextBlock[]
	type: 'success' | 'error' | 'warning' | 'info'
	uid?: string
	isHeaderNotification?: boolean
	order?: number
}

const containerStyle = (scrollPosition: number, width?: number) => {
	let navbarHeight = 128
	if (width) {
		if (width < 592) navbarHeight = 82
		else if (width < 1023) navbarHeight = 94
	}
	return {
		width: 'auto',
		inset: 0,
		marginTop: scrollPosition < navbarHeight ? navbarHeight + 10 - scrollPosition : 10,
		marginRight: '4%',
		zIndex: 1002, // * navbar is 1001
	}
}

const DEFAULT_OPTIONS = {
	duration: Infinity,
	type: 'info',
}

const toastOptions = {
	...DEFAULT_OPTIONS,
}

// * This is the function you want to use to create toasts
export const createAlert = (notification: INotification, options?: ReactHotToast.ToastOptions) => {
	const toastOptions = {
		...DEFAULT_OPTIONS,
		...options,
	}
	return ReactHotToast.toast(notification as any, toastOptions) // React Hot Toast doesn't support type `INotification`
}

// * The Toast provider
export default function Toaster() {
	const [windowWidth, setWindowWidth] = useState<number | undefined>(undefined)
	const [scrollPosition, setScrollPosition] = useState<number>(0)

	const [previousNotifications, setPreviousNotifications] = useState<string[]>([])

	const handleScroll = useCallback(() => {
		if (typeof window !== 'undefined') {
			setScrollPosition(window.scrollY)
		}
	}, [])

	const handleResize = useCallback(() => {
		if (typeof window !== 'undefined') {
			setWindowWidth(window.innerWidth)
		}
	}, [])

	useEffect(() => {
		setPreviousNotifications(
			JSON.parse(
				// * get previous notifications from sessionStorage
				sessionStorage.getItem('seenNotifications') || '[]'
			)
		)

		if (typeof window !== 'undefined') {
			setWindowWidth(window.innerWidth)
			window.addEventListener('resize', handleResize)
			window.addEventListener('scroll', handleScroll, { passive: true })
		}

		return () => {
			if (typeof window !== 'undefined') {
				window.removeEventListener('resize', handleResize)
				window.removeEventListener('scroll', handleScroll)
			}
		}
	}, [])

	return (
		<ReactHotToast.Toaster
			position='top-right'
			toastOptions={toastOptions}
			containerStyle={containerStyle(scrollPosition, windowWidth)}
		>
			{(t) => {
				const title = get(t, 'message.title', '')
				const message = get(t, 'message.message', '')
				const type = get(t, 'message.type', undefined)
				const uid = get(t, 'message.uid', '')
				const onHeader: boolean = get(t, 'message.isHeaderNotification', false)
				const order = get(t, 'message.order', 0)

				const onClose = () => {
					ReactHotToast.toast.dismiss(t.id)

					if (uid) {
						// * This prevents the user from seeing the same notification twice during the same session
						const nextNotifications = [...previousNotifications, uid]
						sessionStorage.setItem('seenNotifications', JSON.stringify(nextNotifications))
					}
				}

				if (previousNotifications.includes(uid)) {
					// * Don't return anything - the notification has already been seen
					return <></>
				}

				return (
					<>
						{!onHeader && (
							<Alert
								visible={t.visible}
								title={title}
								message={message}
								t={t}
								type={type}
								onClose={onClose}
							/>
						)}
						{onHeader && (
							<HeaderNotification
								visible={t.visible}
								title={title}
								message={message}
								type={type}
								onClose={onClose}
								index={order}
							/>
						)}
					</>
				)
			}}
		</ReactHotToast.Toaster>
	)
}
