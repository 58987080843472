import { FC } from 'react'

import Arrow from './Arrow'
import BrandLogo from './BrandLogo'
import Checkmark from './Checkmark'
import CollapsableArrow from './CollapsableArrow'
import ErrorIcon from './ErrorIcon'
import ExtendArrow from './ExtendArrow'
import FullLogo from './FullLogo'
import FullLogoSecondary from './FullLogoSecondary'
import GenericFile from './GenericFile'
import Globe from './Globe'
import Hamburger from './Hamburger'
import Info from './Info'
import LineBorder from './LineBorder'
import Lock from './Lock'
import OpenMenu from './OpenMenu'
import PDF from './PDF'
import Union from './Union'
import Warning from './Warning'
import MemoCircleInfoRegular from './MemoCircleInfoRegular'
import GreatherThan from './GreatherThan'
import { KvikaSVGProps } from './types'

const Icons: Record<string, FC<KvikaSVGProps>> = {
	PDF,
	CollapsableArrow,
	GenericFile,
	Lock,
	LineBorder,
	ExtendArrow,
	Union,
}

export {
	Arrow,
	BrandLogo,
	Checkmark,
	CollapsableArrow,
	ErrorIcon,
	ExtendArrow,
	FullLogo,
	FullLogoSecondary,
	GenericFile,
	Globe,
	Hamburger,
	Info,
	LineBorder,
	Lock,
	OpenMenu,
	PDF,
	Warning,
	MemoCircleInfoRegular,
	GreatherThan,
}

export default Icons
