import * as React from 'react'

import { KvikaSVGProps } from './types'

const Info = ({ fill = 'var(--baby-blue-800)' }: KvikaSVGProps) => (
	<svg
		width={24}
		height={24}
		viewBox='0 0 24 24'
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
	>
		<g clipPath='url(#clip0_1_7437)'>
			<path
				d='M12 9C12.8283 9 13.5 8.32828 13.5 7.5C13.5 6.67172 12.8283 6 12 6C11.1717 6 10.5 6.67031 10.5 7.5C10.5 8.32969 11.1703 9 12 9ZM13.875 15.75H13.125V11.625C13.125 11.0062 12.6234 10.5 12 10.5H10.5C9.88125 10.5 9.375 11.0062 9.375 11.625C9.375 12.2438 9.88125 12.75 10.5 12.75H10.875V15.75H10.125C9.50625 15.75 9 16.2563 9 16.875C9 17.4937 9.50625 18 10.125 18H13.875C14.4961 18 15 17.4961 15 16.875C15 16.2539 14.4984 15.75 13.875 15.75Z'
				fill={fill}
			/>
			<path
				opacity={0.4}
				d='M12 0C5.37188 0 0 5.37188 0 12C0 18.6281 5.37188 24 12 24C18.6281 24 24 18.6281 24 12C24 5.37188 18.6281 0 12 0ZM12 6C12.8283 6 13.5 6.67172 13.5 7.5C13.5 8.32828 12.8283 9 12 9C11.1717 9 10.5 8.32969 10.5 7.5C10.5 6.67031 11.1703 6 12 6ZM13.875 18H10.125C9.50625 18 9 17.4984 9 16.875C9 16.2516 9.50391 15.75 10.125 15.75H10.875V12.75H10.5C9.87891 12.75 9.375 12.2461 9.375 11.625C9.375 11.0039 9.88125 10.5 10.5 10.5H12C12.6211 10.5 13.125 11.0039 13.125 11.625V15.75H13.875C14.4961 15.75 15 16.2539 15 16.875C15 17.4961 14.4984 18 13.875 18Z'
				fill={fill}
			/>
		</g>
		<defs>
			<clipPath id='clip0_1_7437'>
				<rect
					width={24}
					height={24}
					fill='white'
				/>
			</clipPath>
		</defs>
	</svg>
)

export default Info
