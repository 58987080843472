import { css } from 'styled-components'

import { media } from '../../utils/style-utils'

export const CardStyles = css<{ backgroundColor?: string }>`
	background: ${({ backgroundColor }) => backgroundColor};
	border-radius: 56px;
	z-index: 1;
	${media.tablet`
		border-radius: 40px;
	`}
`

export const CardAspectStyles = css`
	overflow: hidden;
	aspect-ratio: 1/1;

	${media.tablet`
		aspect-ratio: 15/8;
		min-width: unset;
		width: 100%;
		height: 100%;
		max-height: 600px;
	`};

	${media.tabletMd`
		min-height: 428px;
		max-height: unset;
		aspect-ratio: unset;
	`}
`

export const CardPadding = `9%`
