import * as React from 'react'

import { KvikaSVGProps } from './types'

const Error = ({ fill = 'var(--danger-red-500)' }: KvikaSVGProps) => (
	<svg
		width={24}
		height={24}
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<g clipPath='url(#clip0_1_7972)'>
			<path
				d='M12.0001 14.25C12.6212 14.25 13.1251 13.7461 13.1251 13.125V7.125C13.1251 6.50625 12.6236 6 12.0001 6C11.3767 6 10.8751 6.50625 10.8751 7.125V13.125C10.8751 13.7484 11.3814 14.25 12.0001 14.25ZM12.0001 15.8016C11.1864 15.8016 10.5264 16.4616 10.5264 17.2753C10.5282 18.0891 11.1845 18.75 12.0001 18.75C12.8157 18.75 13.4739 18.09 13.4739 17.2762C13.472 16.4625 12.8157 15.8016 12.0001 15.8016Z'
				fill={fill}
			/>
			<path
				opacity={0.4}
				d='M12 0C5.37188 0 0 5.37188 0 12C0 18.6281 5.37188 24 12 24C18.6281 24 24 18.6281 24 12C24 5.37188 18.6281 0 12 0ZM10.875 7.125C10.875 6.50625 11.3813 6 12 6C12.6187 6 13.125 6.50391 13.125 7.125V13.125C13.125 13.7461 12.6211 14.25 12 14.25C11.3789 14.25 10.875 13.7484 10.875 13.125V7.125ZM12 18.75C11.1862 18.75 10.5262 18.09 10.5262 17.2762C10.5262 16.4625 11.1858 15.8025 12 15.8025C12.8142 15.8025 13.4738 16.4625 13.4738 17.2762C13.4719 18.0891 12.8156 18.75 12 18.75Z'
				fill={fill}
			/>
		</g>
		<defs>
			<clipPath id='clip0_1_7972'>
				<rect
					width={24}
					height={24}
					fill='white'
				/>
			</clipPath>
		</defs>
	</svg>
)

export default Error
