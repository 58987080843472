import { get } from 'lodash'
import { useRouter } from 'next/router'

import translations from '../../locale/translations'

type key = string
type options = {
	fallback?: string
	locale?: string
	values?: object // Object of keys that translate() uses to replace {{key}}
}

// * Example usage:
// * translation = { rate: 'rate is {{ x }}' }
// * translate('rate', { values: { x: '1.5' } })
// * => 'rate is 1.5'

const useLocalization = (): { translate: Function; locale: string } => {
	const router = useRouter()
	const routeLocale = get(router, 'locale', 'is')

	const translate = (key: key, options?: options): string => {
		const { locale, fallback = '', values } = options || {}
		const translationLocale = locale ?? routeLocale
		const translation = get(translations, `${translationLocale}.${key}`, fallback)

		const interpolate = (translation) =>
			// * Replaces {{key}} with options.values
			// * See example above
			translation.replace(/\{{(\w+)}}/g, (_, v) => {
				// * get ensures we don't throw an error if the key doesn't exist
				// * also allows us to use key as fallback if the key parameter doesn't exist
				const interpolated = get(values, v, v)
				return interpolated
			})

		// * if we have options.values, interpolate the translation
		// * otherwise just return the translation
		return values ? interpolate(translation) : translation
	}

	return { translate, locale: routeLocale }
}

export default useLocalization
