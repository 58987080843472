import localFont from '@next/font/local'
import { createGlobalStyle, css } from 'styled-components'

import { media } from '../utils/style-utils'

const atypFont = localFont({
	src: [
		{
			path: '../assets/fonts/AtypDisplay-Regular.woff2',
			weight: '400',
		},
		{
			path: '../assets/fonts/AtypDisplay-Medium.woff2',
			weight: '500',
		},
	],
	fallback: ['ui-sans-serif'],
	display: 'swap',
})

const akzidenzFont = localFont({
	src: [
		{
			path: '../assets/fonts/akzidenzgroteskpro-light-webfont.woff2',
			weight: '300',
		},
		{
			path: '../assets/fonts/akzidenzgroteskpro-regular-webfont.woff2',
			weight: '400',
		},
		{
			path: '../assets/fonts/akzidenzgroteskpro-md-webfont.woff2',
			weight: '500',
		},
		{
			path: '../assets/fonts/akzidenzgroteskpro-bold-webfont.woff2',
			weight: '700',
		},
		{
			path: '../assets/fonts/akzidenzgroteskpro-it-webfont.woff2',
			weight: 'normal',
			style: 'italic',
		},
	],
	fallback: [],
	display: 'swap',
})

export const ColorsEnum = {
	Neon: 'var(--neon)',
	'Deep Blue': 'var(--deep-blue)',
	'Baby Blue': 'var(--baby-blue-400)',
}

export const typography = css`
	body {
		background: var(--off-white);
		font-family: var(--font-akzidenz);
		font-style: normal;
		font-weight: 300;
		font-size: 1.25rem;
		line-height: 2rem;
		color: var(--deep-blue);
		hyphens: manual;
		word-break: break-word;
	}

	p {
		font-weight: 300;
		font-size: 1.125rem;
		font-size: clamp(1rem, 1vw + 0.5rem, 2.5rem);
		${media.desktopSm`
			font-size: clamp(.875rem, 1vw + 0.25rem, 2.5rem);
		`}
		${media.phone`
			font-size: clamp(1rem, 1vw + 0.25rem, 2.5rem);
		`}
		${media.phoneXs`
			font-size: clamp(.75rem, 1vw + 0.25rem, 2.5rem);
		`}
		line-height: 140%;
	}

	p.display,
	p > span.display {
		font-family: var(--font-atyp-display);
		font-weight: 300;
		line-height: 140%;

		font-size: clamp(2.125rem, 165%, 3.5rem);

		${media.desktopSm`
			font-size: clamp(.875rem, 165%, 2.5rem);
		`}
		${media.phoneXs`
			font-size: clamp(.75rem, 165%, 2.5rem);
		`}
	}
	h1 {
		font-family: var(--font-atyp-display);
		font-style: normal;
		font-weight: 500;
		font-size: clamp(2.5rem, 4vw + 1rem, 8.75rem);
		line-height: 110%;
		font-feature-settings: 'liga' off;
	}
	h2 {
		font-family: var(--font-atyp-display);
		font-style: normal;
		font-weight: 400;
		font-size: 2.5rem;
		font-size: clamp(1.25rem, 3vw + 1rem, 5rem);
		line-height: 3.5rem;
	}
	h3 {
		font-family: var(--font-atyp-display);
		font-style: normal;
		font-weight: 400;
		font-size: clamp(1.25rem, 1vw + 1rem, 3.5rem);
		line-height: 2.5rem;
	}
	h4 {
		font-family: var(--font-atyp-display);
		font-style: normal;
		font-weight: 500;
		font-size: clamp(1.25rem, 1vw + 0.5rem, 2.75rem);
		line-height: 108%;
	}
	h5 {
		font-family: var(--font-atyp-display);
		font-style: normal;
		font-weight: 500;
		font-size: 1.375rem;
		line-height: 1.5rem;
	}
	h6 {
		font-family: var(--font-atyp-display);
		font-style: normal;
		font-weight: 500;
		font-size: 1.25rem;
		line-height: 1.5rem;
	}
	h1.title {
		font-family: var(--font-atyp-display);
		font-style: normal;
		font-weight: 500;
		font-size: 4.5rem;
		line-height: 5rem;
	}
	h1.display {
		// * .display is only used occasionally and totally as a one-off.
		font-family: var(--font-atyp-display);
		font-style: normal;
		font-weight: 500;
		font-size: 4rem;
		line-height: 108.6%; // ! As per the figma stylesheet
	}
	h1.heading {
		font-family: var(--font-atyp-display);
		font-style: normal;
		font-weight: 400;
		font-size: 3.5rem;
		line-height: 108.6%;
		font-feature-settings: 'liga' off;
	}
	h2.heading {
		font-family: var(--font-atyp-display);
		font-style: normal;
		font-weight: 500;
		font-size: 3rem;
		line-height: 100%;
	}
	.subtitle {
		// * only to be used below headings
		font-family: var(--font-atyp-display);
		font-style: normal;
		font-weight: 400;
		font-size: 2.5rem;
		line-height: 3.5rem;
	}

	strong {
		font-weight: 500;
	}
`
const colors = css`
	:root {
		--deep-blue: #002649;
		--neon: #dafda3;
		--neon-100: #f0feda;
		--orange-100: #ffdbcc;
		--orange: #ff8b59;
		--baby-blue-100: #eff8fa;
		--baby-blue-200: #e2f9fe;
		--baby-blue-400: #bce6f3; // was called baby-blue in straumur web design, but has been changed to blue-400 in main straumur design
		--baby-blue: var(--baby-blue-400); // Retained to maintain consistency with design system
		--baby-blue-800: #08576f;
		--cream: #fcf8f2;
		--grey: #e2e6e9;
		--off-white: #fbfaf9;
		--black: #262b31;
		--blue-50: #f5f8fa;
		--blue-100: #eff2f5;
		--blue-150: #e6ebef;
		--blue-300: #cdd8e2;
		--blue-400: #72889d;
		--blue-500: #4d5f6f;
		--blue-700: #0a3761;
		--input-text-color: #6a6f77;
		--positive-green-100: #f0fbd8;
		--positive-green-300: #c8e98a; // AI generated
		--positive-green-400: #9bd71a;
		--positive-green-400-025: rgba(155, 215, 26, 0.25); // 25% of green-400
		--positive-green-500: #7bbd00; // AI generated
		--positive-green-600: #5b8206;
		--danger-red-50: #fff8f5;
		--danger-red-100: #ffede5;
		--danger-red-500: #d03e00;
		--danger-red-500-025: rgba(208, 62, 0, 0.25); // 25% of red-500
		--warning-yellow-100: #fff7db;
		--warning-yellow-400: #dfae00;
		--warning-yellow-400-025: rgba(223, 174, 0, 0.25); // 25% of yellow-400
		--warning-yellow-800: #775d00;
		--classic-white: #fff;
		--question-mark-color: #548526;
		--step-card: #f7feeb;
	}
`

export const easings = {
	cubicInOut: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
	cubicIn: 'cubic-bezier(0.32, 0, 0.67, 0)',
	expoInOut: 'cubic-bezier(0.9, 0.05, 0.2, 1)',
}

const GlobalStyle = createGlobalStyle<{ $slicemachine?: boolean }>`
:root {
	--spacer-2xs: 8px;
	--spacer-xs: 16px;
	--spacer-sm: 24px;
	--spacer-md: 32px;
	--spacer-lg: 56px;
	--spacer-base: 80px; // most commonly used
	--spacer-xl: 120px;
	--spacer-2xl: 200px;
	--animation-transition: 300ms;
	--font-atyp-display: ${atypFont.style.fontFamily};
	--font-akzidenz: ${akzidenzFont.style.fontFamily};
}

${typography}
${colors}
/* Default styles */
html,
body {
	margin:0;
}

a {
  color: inherit;
  text-decoration: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

#home > iframe {
	border-radius: 40px !important;
}

/* This hides cookiehub button in slice simulator */
section:first-of-type a[aria-label="Stillingar"],
section:first-of-type a[aria-label="Settings"] {
	${(props) => props.$slicemachine && 'display: none !important'};
}

`

export default GlobalStyle
