import * as React from 'react'
const RATIO = 226 / 28

const FullLogo = ({ size = 28, fill = '#DAFDA3', ...rest }) => {
	return (
		<svg
			width={size ? RATIO * size : 164}
			height={size ? size : 20}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 164 20'
			{...rest}
		>
			<path
				d='M42.3219 15.778L45.3794 15.1373C45.6504 16.7287 46.7367 17.5234 48.6402 17.5234C49.6094 17.5234 50.3917 17.3345 50.9831 16.9566C51.5744 16.5788 51.8701 16.0983 51.8701 15.5152C51.8701 14.895 51.5601 14.4227 50.9379 14.1024C50.3157 13.7821 49.5601 13.5501 48.6668 13.4043C47.7736 13.2585 46.8763 13.0798 45.9749 12.8663C45.0734 12.6527 44.3116 12.2318 43.6894 11.5993C43.0673 10.969 42.7572 10.1188 42.7572 9.05314C42.7572 7.75335 43.3157 6.67532 44.4307 5.82111C45.5457 4.9669 46.9482 4.53979 48.636 4.53979C50.3239 4.53979 51.7079 4.91762 52.7839 5.67532C53.8619 6.43302 54.5067 7.46177 54.7202 8.76156L51.6648 9.40222C51.4328 7.81084 50.4225 7.01618 48.6381 7.01618C47.8229 7.01618 47.1535 7.19072 46.6299 7.53979C46.1063 7.88887 45.8434 8.34472 45.8434 8.90735C45.8434 9.41248 46.0673 9.81905 46.5128 10.1291C46.9584 10.4392 47.5211 10.6425 48.2007 10.741C48.8804 10.8375 49.6135 10.9833 50.3979 11.1784C51.1843 11.3735 51.9153 11.5911 52.595 11.8334C53.2746 12.0757 53.8373 12.5069 54.2829 13.1291C54.7284 13.7513 54.9523 14.5254 54.9523 15.4577C54.9523 16.7575 54.3465 17.8396 53.133 18.7041C51.9194 19.5685 50.4204 19.9997 48.636 19.9997C46.8517 19.9997 45.4348 19.6219 44.328 18.8642C43.2213 18.1065 42.5519 17.0798 42.3198 15.778H42.3219Z'
				fill={fill}
			/>
			<path
				d='M59.1563 7.54004H55.9838V4.83367H59.1563V0H62.2117V4.83367H66.4027V7.54004H62.2117V14.4107C62.2117 15.3224 62.415 15.9836 62.8236 16.3901C63.2302 16.7988 63.8811 17.002 64.7743 17.002H66.4047V19.7084H64.7743C62.8523 19.7084 61.4355 19.2628 60.5238 18.3696C59.6121 17.4764 59.1563 16.1088 59.1563 14.2649V7.54004Z'
				fill={fill}
			/>
			<path
				d='M72.2915 4.83382V8.764C72.7761 6.99809 73.5379 5.80507 74.5769 5.1829C75.6139 4.56277 76.7946 4.40672 78.1129 4.71678V7.62848C76.501 7.18289 75.1293 7.32253 73.9938 8.05148C72.8583 8.77838 72.2915 9.86051 72.2915 11.2979V19.7106H69.2361V4.83382H72.2915Z'
				fill={fill}
			/>
			<path
				d='M85.6609 4.54199C87.4453 4.54199 88.9012 5.00811 90.0264 5.9383C91.1517 6.87053 91.7143 8.20934 91.7143 9.95472V19.7063H88.6589V15.7473C87.7472 18.581 85.9135 19.9978 83.1578 19.9978C81.8765 19.9978 80.8088 19.6139 79.9566 18.8479C79.1024 18.082 78.6753 17.0779 78.6753 15.8356C78.6753 14.3798 79.1886 13.2894 80.2174 12.5605C81.2461 11.8336 82.5562 11.3818 84.1476 11.2073L87.0285 10.9157C88.1147 10.8192 88.6589 10.3346 88.6589 9.45985C88.6589 8.74117 88.3817 8.17033 87.8293 7.74322C87.2769 7.31612 86.5521 7.10257 85.6609 7.10257C84.7698 7.10257 83.9771 7.3346 83.3468 7.80072C82.7164 8.26684 82.3139 8.94651 82.1394 9.83768L79.1127 9.11078C79.3837 7.71448 80.1127 6.60359 81.2954 5.77813C82.4782 4.95267 83.934 4.54199 85.6609 4.54199ZM84.1476 17.5256C85.4084 17.5256 86.4761 17.1026 87.3488 16.2586C88.2215 15.4147 88.6589 14.4291 88.6589 13.3038V12.4886C88.4268 12.8767 87.8622 13.119 86.971 13.2155L84.1476 13.5646C83.4104 13.6611 82.8231 13.8808 82.3858 14.2196C81.9484 14.5584 81.7307 15.0204 81.7307 15.6015C81.7307 16.1826 81.9484 16.6488 82.3858 16.9978C82.8231 17.3469 83.4084 17.5215 84.1476 17.5215V17.5256Z'
				fill={fill}
			/>
			<path
				d='M94.7987 13.536V4.83374H97.8541V13.5381C97.8541 14.6243 98.2032 15.5031 98.9014 16.1725C99.5995 16.8419 100.493 17.1767 101.579 17.1767C102.665 17.1767 103.53 16.8419 104.228 16.1725C104.926 15.5031 105.275 14.6243 105.275 13.5381V4.83374H108.333V13.5381C108.333 15.4395 107.696 16.9918 106.425 18.1951C105.154 19.3984 103.538 20.0001 101.579 20.0001C99.62 20.0001 97.9732 19.3984 96.7042 18.1951C95.4332 16.9918 94.7966 15.4395 94.7966 13.5381L94.7987 13.536Z'
				fill={fill}
			/>
			<path
				d='M129.23 4.54199C130.762 4.54199 132.018 5.13337 133 6.31817C133.979 7.50298 134.47 9.00606 134.47 10.8295V19.7083H131.415V11.2956C131.415 10.1498 131.098 9.20524 130.468 8.4578C129.838 7.71037 129.055 7.33665 128.125 7.33665C127.078 7.33665 126.217 7.71037 125.548 8.4578C124.879 9.20524 124.544 10.1518 124.544 11.2956V19.7083H121.489V11.2956C121.489 10.1498 121.178 9.20524 120.556 8.4578C119.934 7.71037 119.16 7.33665 118.228 7.33665C117.181 7.33665 116.32 7.71037 115.651 8.4578C114.981 9.20524 114.647 10.1518 114.647 11.2956V19.7083H111.591V4.83357H114.647V8.47218C115.053 7.25041 115.669 6.28943 116.495 5.59127C117.32 4.89312 118.285 4.54405 119.39 4.54405C120.573 4.54405 121.597 4.91776 122.462 5.66519C123.324 6.41263 123.932 7.41673 124.281 8.67752C124.63 7.41673 125.246 6.41263 126.129 5.66519C127.012 4.91776 128.045 4.54405 129.23 4.54405V4.54199Z'
				fill={fill}
			/>
			<path
				d='M137.484 13.536V4.83374H140.54V13.5381C140.54 14.6243 140.889 15.5031 141.587 16.1725C142.285 16.8419 143.179 17.1767 144.265 17.1767C145.351 17.1767 146.215 16.8419 146.914 16.1725C147.612 15.5031 147.961 14.6243 147.961 13.5381V4.83374H151.018V13.5381C151.018 15.4395 150.382 16.9918 149.111 18.1951C147.84 19.3984 146.224 20.0001 144.265 20.0001C142.306 20.0001 140.659 19.3984 139.39 18.1951C138.119 16.9918 137.482 15.4395 137.482 13.5381L137.484 13.536Z'
				fill={fill}
			/>
			<path
				d='M157.36 4.83382V8.764C157.844 6.99809 158.606 5.80507 159.645 5.1829C160.682 4.56277 161.863 4.40672 163.181 4.71678V7.62848C161.569 7.18289 160.197 7.32253 159.062 8.05148C157.926 8.77838 157.36 9.86051 157.36 11.2979V19.7106H154.304V4.83382H157.36Z'
				fill={fill}
			/>
			<path
				d='M25.977 15.8438C28.0262 15.8438 29.6874 14.1826 29.6874 12.1333C29.6874 10.0841 28.0262 8.42285 25.977 8.42285C23.9277 8.42285 22.2665 10.0841 22.2665 12.1333C22.2665 14.1826 23.9277 15.8438 25.977 15.8438Z'
				fill={fill}
			/>
			<path
				d='M14.8452 15.8438C16.8945 15.8438 18.5557 14.1826 18.5557 12.1333C18.5557 10.0841 16.8945 8.42285 14.8452 8.42285C12.796 8.42285 11.1348 10.0841 11.1348 12.1333C11.1348 14.1826 12.796 15.8438 14.8452 15.8438Z'
				fill={fill}
			/>
			<path
				d='M3.71047 15.8438C5.75971 15.8438 7.42094 14.1826 7.42094 12.1333C7.42094 10.0841 5.75971 8.42285 3.71047 8.42285C1.66124 8.42285 0 10.0841 0 12.1333C0 14.1826 1.66124 15.8438 3.71047 15.8438Z'
				fill={fill}
			/>
			<path
				d='M25.9774 11.3118H3.71289V12.9545H25.9774V11.3118Z'
				fill={fill}
			/>
		</svg>
	)
}

export default FullLogo
