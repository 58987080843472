import * as React from 'react'

import { KvikaSVGProps } from './types'

const Warning = ({ fill = 'var(--warning-yellow-400)' }: KvikaSVGProps) => (
	<svg
		width={24}
		height={24}
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<g clipPath='url(#clip0_1_7975)'>
			<path
				d='M12.0001 15C12.6236 15 13.1251 14.4984 13.1251 13.875V7.875C13.1251 7.25391 12.6212 6.75 12.0423 6.75C11.4634 6.75 10.8751 7.25625 10.8751 7.875V13.875C10.8751 14.4984 11.3814 15 12.0001 15ZM12.0001 16.5516C11.1864 16.5516 10.5264 17.2116 10.5264 18.0253C10.5282 18.8391 11.1845 19.5 12.0001 19.5C12.8157 19.5 13.4739 18.84 13.4739 18.0262C13.472 17.2125 12.8157 16.5516 12.0001 16.5516Z'
				fill={fill}
			/>
			<path
				opacity={0.4}
				d='M23.7327 19.5469L13.7342 2.48438C12.9687 1.17188 11.037 1.17188 10.2664 2.48438L0.272653 19.5469C-0.496566 20.8547 0.461513 22.5 2.00328 22.5H22.0002C23.5358 22.5 24.4967 20.8594 23.7327 19.5469ZM10.8748 7.875C10.8748 7.25391 11.3787 6.75 11.9998 6.75C12.6209 6.75 13.1248 7.25625 13.1248 7.875V13.875C13.1248 14.4961 12.6209 15 12.042 15C11.4631 15 10.8748 14.4984 10.8748 13.875V7.875ZM11.9998 19.5C11.1861 19.5 10.5261 18.84 10.5261 18.0262C10.5261 17.2125 11.1856 16.5525 11.9998 16.5525C12.8141 16.5525 13.4736 17.2125 13.4736 18.0262C13.4717 18.8391 12.8155 19.5 11.9998 19.5Z'
				fill={fill}
			/>
		</g>
		<defs>
			<clipPath id='clip0_1_7975'>
				<rect
					width={24}
					height={24}
					fill='white'
				/>
			</clipPath>
		</defs>
	</svg>
)

export default Warning
