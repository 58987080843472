const translations = {
	is: {
		general: {
			submit: 'Senda',
			isSubmitting: 'Sendi',
			close: 'Loka',
		},
		contactForm: {
			name: 'Fullt nafn',
			email: 'Netfang',
			messagePlaceholder: 'Skilaboðin þín hér',
			inquiryTypes: {
				compliance: 'Kvörtun',
				general: 'Almenn fyrirspurn',
			},
			error: {
				title: 'Gat ekki sent skilaboðin',
				message: 'Vinsamlegast hafðu samband við þjónustuver eða reyndu aftur síðar',
			},
			success: {
				title: 'Skilaboðin þín voru send',
				message: 'Við munum svara þér eins fljótt og mögulegt er',
			},
		},
		qna: {
			seeMoreQuestions: 'Sjá fleiri algengar spurningar',
		},
		productHighlight: {
			moreAboutPos: 'Nánar um posa',
			applyFor: 'Sækja um',
		},
		requestOfferForm: {
			inputSectionTitle: 'Fyrirtæki og tengiliður',
			ssn: 'Kennitala fyrirtækis',
			name: 'Nafn tengiliðs',
			email: 'Netfang tengiliðs',
			phone: 'Símanúmer tengiliðs',
			estimated_turnover: 'Áætluð velta',
			estimated_average_transaction: 'Áætluð meðalfærsla',
			product_or_service_type: 'Hvað ertu að selja?',
			organization_website: 'Vefsíða félagsins',
			other_details: 'Annað',
			pricing_type: 'Tegund gjalda',
			pricing_typeOptions: ['Föst þóknun', 'Interchange ++'],
			checkBoxGroupLable: 'Greiðslulausnir',
			radioButtonGroupLable: 'Fyrirkomulag gjalda',
			checkButtons: ['Posi', 'Veflausn'],
			platform_selection: 'Veldu veflausn/ir',
			checkButtonsOptional: [
				'Shopify / WooCommerce / Presta / Magneto (Adobe Commerce)',
				'Áskriftagreiðslur',
				'Greiðslutenglar',
				'Vefposi',
				'Sportabler / Salescloud',
			],
			radioButtons: ['Föst verð', 'Millikortagjöld', 'Er ekki viss'],
			submitButton: 'Óska eftir tilboði',
			error: {
				title: 'Couldn´t send your request',
				message: 'Please contact customer service or try again later',
			},
			success: {
				title: 'Tilboðsbeiðni þín hefur verið móttekin',
				message: 'Við munum hafa samband eins fljótt og mögulegt er',
			},
		},
		other: 'Annað',
		recaptcha: {
			digits: {
				'0': 'núll',
				'1': 'einn',
				'2': 'tveir',
				'3': 'þrír',
				'4': 'fjórir',
				'5': 'fimm',
				'6': 'sex',
				'7': 'sjö',
				'8': 'átta',
				'9': 'níu',
			},
			minus: 'mínus',
			plus: 'plús',
			exrpressionStart: 'Hvað er ',
		},
	},
	en: {
		general: {
			submit: 'Send',
			isSubmitting: 'Sending',
			close: 'Loka',
		},
		contactForm: {
			name: 'Full name',
			email: 'E-mail',
			messagePlaceholder: 'Write your message here',
			inquiryTypes: {
				compliance: 'Complaint',
				general: 'General inquiry',
			},
			error: {
				title: 'Couldn´t send your message',
				message: 'Please contact customer service or try again later',
			},
			success: {
				title: 'Your message was sent',
				message: 'We will get back to you as soon as possible',
			},
		},
		qna: {
			seeMoreQuestions: 'See more common questions',
		},
		productHighlight: {
			moreAboutPos: 'More about posa',
			applyFor: 'Apply for',
		},
		requestOfferForm: {
			inputSectionTitle: 'Company and Contact',
			ssn: 'Company identification number',
			name: 'Contact name',
			email: 'Email address',
			phone: 'Phone number',
			estimated_turnover: 'Estimated turnover',
			estimated_average_transaction: 'Estimated average transaction',
			product_or_service_type: 'What are you selling?',
			organization_website: 'Website',
			other_details: 'Other',
			pricing_type: 'Pricing Type',
			pricing_typeOptions: ['Blended Pricing', 'Interchange++'],
			checkBoxGroupLable: 'Payment solutions',
			radioButtonGroupLable: 'Arrangement of fees',
			checkButtons: ['Pos', 'Web solution'],
			platform_selection: 'Platform selection',
			checkButtonsOptional: [
				'WooCommerce / Presta / Magneto (Adobe Commerce)',
				'Áskriftagreiðslur',
				'Greiðslutenglar',
				'Vefposi',
				'Sportables/Salescloud',
			],
			radioButtons: ['Fixed price', 'Intercard fees', 'Not sure'],
			submitButton: 'Request an offer',
			error: {
				title: 'Couldn´t send your request',
				message: 'Please contact customer service or try again later',
			},
			success: {
				title: 'Your request was sent',
				message: 'We will get back to you as soon as possible',
			},
		},
		other: 'Other',
		recaptcha: {
			digits: {
				'0': 'zero',
				'1': 'one',
				'2': 'two',
				'3': 'three',
				'4': 'four',
				'5': 'five',
				'6': 'six',
				'7': 'seven',
				'8': 'eight',
				'9': 'nine',
			},
			minus: 'minus',
			plus: 'plus',
			exrpressionStart: 'How much is ',
		},
	},
}

export default translations
