import React from 'react'
import styled, { css } from 'styled-components'

import { AutoSpace, media } from '../../src/utils/style-utils'

type Spaces = '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'base' | '2xl'

export interface ISpacerSlice {
	slice: {
		primary: {
			desktop: Spaces
			tablet: Spaces
			phone: Spaces
			negative: boolean
			auto: boolean
		}
	}
}

const Spacer: React.FC<ISpacerSlice> = ({
	slice: {
		primary: { desktop, tablet, phone, negative, auto = true },
	},
}) => {
	if (!desktop && !tablet && !phone && !auto) {
		return null
	}

	if (auto) {
		return <AutoSpacer negative={negative} />
	}

	return (
		<SpacerWrapper
			desktop={desktop}
			tablet={tablet}
			phone={phone}
			negative={negative}
		/>
	)
}

export default Spacer

export const AutoSpacer = styled.div<{ negative: boolean }>`
	height: ${AutoSpace['desktop']}px;

	${media.desktopHuge`
		height: ${AutoSpace['desktopHuge']}px;
	`}

	${media.tablet`
		height: ${AutoSpace['tablet']}px;
	`}

	${media.phone`
		height: ${AutoSpace['phone']}px;
	`}

	${(p) =>
		p.negative &&
		css`
			margin: -${AutoSpace['desktop']}px;
			${media.desktopHuge`
				margin: -${AutoSpace['desktopLg']};

			`}

			${media.tablet`
				margin: -${AutoSpace['tablet']};
			`}

			${media.phone`
				margin: -${AutoSpace['phone']};
			`}
		`}
`

const SpacerWrapper = styled.div<{
	desktop: Spaces
	tablet: Spaces
	phone: Spaces
	negative: boolean
}>`
	${(p) =>
		p.negative
			? css`
					margin: calc(var(--spacer-${p.desktop}) * -1);
					${media.tablet`
            margin: calc(var(--spacer-${p.tablet}) * -1);
          `}
					${media.phone`
            margin: calc(var(--spacer-${p.phone}) * -1);
          `}
			  `
			: css`
					height: var(--spacer-${p.desktop});
					${media.tablet`
            height:var(--spacer-${p.tablet});
          `}
					${media.phone`
            height: calc(var(--spacer-${p.phone}) * -1);
          `}
			  `}
`
