import React from 'react'
import { RichText as PrismicRichText, RichTextBlock } from 'prismic-reactjs'
import { RichTextField } from '@prismicio/types'

import HtmlSerializer, { SerializerOptions } from './html-serializer'

interface Props {
	content: RichTextField | RichTextBlock[]
	className?: string
	serializerOptions?: SerializerOptions
}

const RichText: React.FC<Props> = ({ content, className, serializerOptions }) => {

	if (!checkRichText(content)) return null

	return (
		<div className={className}>
			<PrismicRichText
				render={content as RichTextBlock[]}
				htmlSerializer={(...args) => HtmlSerializer(...args, serializerOptions)}
			/>
		</div>
	)
}

export function checkRichText(content: RichTextField | RichTextBlock[]) {
	return (
		content.length == 0 || !((content as RichTextBlock[]).length == 1 && (content as RichTextBlock[])[0].text == '')
	)
}

export default RichText
