import * as React from 'react'

import { KvikaSVGProps } from './types'

const Checkmark = ({ fill = 'var(--positive-green-400)' }: KvikaSVGProps) => (
	<svg
		width={24}
		height={24}
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<g clipPath='url(#clip0_1_7969)'>
			<path
				d='M17.4279 8.07168C17.9389 8.58262 17.9389 9.41699 17.4279 9.92793L11.4279 15.9279C10.917 16.4389 10.0826 16.4389 9.57168 15.9279L6.57168 12.9279C6.06074 12.417 6.06074 11.5826 6.57168 11.0717C7.08262 10.5607 7.91699 10.5607 8.42793 11.0717L10.4998 13.1436L15.5717 8.07168C16.0826 7.56074 16.917 7.56074 17.4279 8.07168Z'
				fill={fill}
			/>
			<path
				opacity={0.4}
				d='M0 12C0 5.37188 5.37188 0 12 0C18.6281 0 24 5.37188 24 12C24 18.6281 18.6281 24 12 24C5.37188 24 0 18.6281 0 12ZM17.4281 9.92813C17.9391 9.41719 17.9391 8.58281 17.4281 8.07187C16.9172 7.56094 16.0828 7.56094 15.5719 8.07187L10.5 13.1437L8.42813 11.0719C7.91719 10.5609 7.08281 10.5609 6.57187 11.0719C6.06094 11.5828 6.06094 12.4172 6.57187 12.9281L9.57187 15.9281C10.0828 16.4391 10.9172 16.4391 11.4281 15.9281L17.4281 9.92813Z'
				fill={fill}
			/>
		</g>
		<defs>
			<clipPath id='clip0_1_7969'>
				<rect
					width={24}
					height={24}
					fill='white'
				/>
			</clipPath>
		</defs>
	</svg>
)

export default Checkmark
