import React from 'react'
import { Toast } from 'react-hot-toast'
import styled, { css, keyframes } from 'styled-components'

import useLocalization from '../../utils/hooks/use-localization'
import { Checkmark, ErrorIcon, Info, Warning } from '../icons'

import ActionButton from './ActionButton'

interface Props {
	title?: string
	message?: string
	type?: string
	className?: string
	visible: boolean
	t?: Toast
	onClose: () => void
}

const Alert: React.FC<Props> = ({ title, message, type = 'info', className, visible, onClose, t }) => {
	const { translate } = useLocalization()
	return (
		<AlertContainer
			type={type}
			className={className}
			visible={visible}
		>
			<Content>
				<IconWrapper>
					{type === 'success' && <Checkmark />}
					{type === 'error' && <ErrorIcon />}
					{type === 'warning' && <Warning />}
					{type === 'info' && <Info />}
				</IconWrapper>
				<AlertText>
					{title && <Title>{title}</Title>}
					{message && <Message>{message}</Message>}
				</AlertText>
			</Content>
			<ActionRow>
				<ActionButton
					onClick={() => onClose()}
					alertType={type}
				>
					{translate('general.close')}
				</ActionButton>
			</ActionRow>
		</AlertContainer>
	)
}

export default Alert

const enterAnimation = keyframes`
  0% {transform: translate3d(0,${1 * -200}%,0); opacity:.5;}
  100% {transform: translate3d(0,0,0); opacity:1;}
`

const exitAnimation = keyframes`
  0% {transform: translate3d(0,0,-1px); opacity:1;}
  100% {transform: translate3d(0,${1 * -150}%,-1px); opacity:0;}
`

const IconWrapper = styled.div`
	margin-top: -4px;
`

const ActionRow = styled.div`
	display: flex;
	justify-content: flex-end;
	padding-bottom: var(--spacer-xs);
	padding-right: var(--spacer-xs);
`

const AlertContainer = styled.div<{ type?: string; visible: boolean }>`
	width: 336px;
	background: var(--positive-green-100);
	border-radius: 8px;
	box-shadow: 0px 7px 5px -4px rgba(0, 0, 0, 0.1);

	${(p) =>
		p.type === 'info' &&
		css`
			background: var(--baby-blue-100);
		`}

	${(p) =>
		p.type === 'warning' &&
		css`
			background: var(--warning-yellow-100);
		`}

	${(p) =>
		p.type === 'error' &&
		css`
			background: var(--danger-red-100);
		`}

	animation: ${(p) =>
		p.visible
			? css`
					${enterAnimation} 0.35s cubic-bezier(.21,1.02,.73,1) forwards
			  `
			: css`
					${exitAnimation} 0.8s forwards cubic-bezier(.06,.71,.55,1)
			  `};
`

const Content = styled.div`
	display: flex;
	justify-content: flex-start;
	gap: var(--spacer-xs);
	padding-top: var(--spacer-sm);
	padding-left: var(--spacer-xs);
	padding-right: var(--spacer-xs);
`

const AlertText = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
`

const Title = styled.h4`
	display: inline-block;
	font-size: 1rem;
	margin: 0;
`
const Message = styled.span`
	font-size: 1rem;
	line-height: 1.5rem;
`
