import { PrismicDocument } from '@prismicio/types'
import Image from 'next/image'
import Link from 'next/link'
import { RichTextBlock } from 'prismic-reactjs'
import styled from 'styled-components'

import { CardStyles } from '../../components/Card'
import { FullLogoSecondary } from '../../components/icons'
import RichText from '../../components/RichText'
import { media } from '../../utils/style-utils'
import { FullScreenCardGridStyles } from '../grid/Grid'
import { FooterDocument } from '../../../prismicio-types'

const Footer = ({ footer }: { footer: FooterDocument }) => {
	return (
		<footer>
			<MainCardContainer>
				<SubWrapper>
					<MainIconContainer>
						<FullLogoSecondary />
					</MainIconContainer>
					<LinksContainer>
						{footer.data.left_column_links.map((link, index) => {
							return (
								<FooterLink
									key={index}
									href={link.link.url !== '' ? link.link.url : '/'}
								>
									<h4>{link.link_text}</h4>
								</FooterLink>
							)
						})}
					</LinksContainer>
					<LinksContainer>
						{footer.data.right_column_links.map((link, index) => (
							<FooterLink
								key={index}
								href={link.link.url !== '' ? link.link.url : '/'}
							>
								<h4>{link.link_text}</h4>
							</FooterLink>
						))}
					</LinksContainer>
					<BottomLinksRow>
						{footer.data.bottom_items.map((link, index) =>
							link.link.url !== '' ? (
								<FooterLink
									$secondary
									key={index}
									href={link.link.url !== '' ? link.link.url : '/'}
								>
									<p>{link.text}</p>
								</FooterLink>
							) : (
								<p key={index}>{link.text}</p>
							)
						)}
					</BottomLinksRow>
				</SubWrapper>
			</MainCardContainer>
			<SubFooter>
				<SubFooterText content={footer.data.text} />
				<SubFooterIconContainer>
					<Link href='https://kvika.is/'>
						<SubFooterIcon
							alt={footer.data.icon.alt ?? 'Kvika logo'}
							src={footer.data.icon.url ?? ''}
							fill
						/>
					</Link>
				</SubFooterIconContainer>
			</SubFooter>
		</footer>
	)
}

const SubFooterText = styled(RichText)`
	grid-column: 2 / 6;
	margin-top: var(--spacer-sm);
	margin-bottom: var(--spacer-sm);

	${media.tabletMd`
		grid-column: 2 / -2;
		text-align: center;
	`}

	a {
		&:hover {
			text-decoration: underline;
		}
	}
`

const SubFooterIconContainer = styled.div`
	position: relative;
	grid-column: 9 / 10;

	${media.tabletMd`
		grid-column: 2 / -2;
		height: 50px;
		margin-bottom: var(--spacer-sm);
	`}
`

const SubFooterIcon = styled(Image)`
	object-fit: contain;
`

const LinksContainer = styled.div`
	width: max-content;
	display: flex;
	flex-direction: column;
	gap: 24px;

	${media.tabletMd`
		width: 100%;
		margin-top: 24px;
	`}
`

const BottomLinksRow = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 24px;
	margin-top: var(--spacer-base);
	color: var(--blue-300);

	${media.tabletMd`
		flex-direction: column;
	`}

	p {
		margin: 0;
		font-weight: 200;
	}
`

const MainIconContainer = styled.div`
	flex-grow: 1;

	${media.tabletMd`
		margin-bottom: 50px;
	`}
`

const FooterLink = styled(Link)<{ $secondary?: boolean }>`
	color: var(--neon);

	h4 {
		margin-top: 0px;
		margin-bottom: 0px;
		font-weight: 400;
	}

	&:hover {
		text-decoration: underline;
	}

	${(p) => p.$secondary && `color: var(--blue-300)`}
`

const MainCardContainer = styled.div`
	${CardStyles};
	${FullScreenCardGridStyles};
	background-color: var(--deep-blue);
	color: white;
	padding-top: var(--spacer-base);
	padding-bottom: var(--spacer-lg);
`

const SubWrapper = styled.div`
	display: flex;
	grid-column: 2/-2;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 15%;

	${media.tablet`
		gap: 5%;
	`}
`

const SubFooter = styled.div`
	${FullScreenCardGridStyles};
`

export default Footer
