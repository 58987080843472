import * as React from 'react'

import { KvikaSVGProps } from './types'

const XMark = ({ fill = 'var(--baby-blue-800)' }: KvikaSVGProps) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill={fill}
	>
		<path
			d='M21.4081 5.41559C22.189 4.6347 22.189 3.36655 21.4081 2.58566C20.6272 1.80478 19.359 1.80478 18.5782 2.58566L12 9.17008L5.41559 2.59191C4.6347 1.81103 3.36655 1.81103 2.58566 2.59191C1.80478 3.37279 1.80478 4.64095 2.58566 5.42183L9.17008 12L2.59191 18.5844C1.81103 19.3653 1.81103 20.6335 2.59191 21.4143C3.37279 22.1952 4.64095 22.1952 5.42183 21.4143L12 14.8299L18.5844 21.4081C19.3653 22.189 20.6335 22.189 21.4143 21.4081C22.1952 20.6272 22.1952 19.359 21.4143 18.5782L14.8299 12L21.4081 5.41559Z'
			fill={fill}
		/>
	</svg>
)

export default XMark
