import * as React from 'react'

import { KvikaSVGProps } from './types'

const GreatherThan = ({ fill = 'var(--baby-blue-800)' }: KvikaSVGProps) => (
	<svg
		width={24}
		height={24}
		viewBox='0 0 24 24'
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
	>
		<g clipPath='url(#clip0_1_7437)'>
			<path
				xmlns='http://www.w3.org/2000/svg'
				d='M16.8152 13.0637C17.4012 12.4777 17.4012 11.5262 16.8152 10.9402L9.31521 3.44024C8.72927 2.8543 7.77771 2.8543 7.19177 3.44024C6.60583 4.02618 6.60583 4.97774 7.19177 5.56368L13.6324 12.0043L7.19646 18.4449C6.61052 19.0309 6.61052 19.9824 7.19646 20.5684C7.7824 21.1543 8.73396 21.1543 9.3199 20.5684L16.8199 13.0684L16.8152 13.0637Z'
				fill='#002649'
			/>
		</g>
		<defs>
			<clipPath id='clip0_1_7437'>
				<rect
					width={24}
					height={24}
					fill='white'
				/>
			</clipPath>
		</defs>
	</svg>
)

export default GreatherThan
