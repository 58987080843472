import { KvikaSVGProps } from './types'

const OpenMenu = ({ fill = 'var(--deep-blue)', size = 32 }: KvikaSVGProps) => {
	return (
		<svg
			width={size}
			height={size}
			fill={fill}
			viewBox='0 0 32 32'
			xmlns='http://www.w3.org/2000/svg'
		>
			<rect
				x={13}
				y={4}
				width={6}
				height={6}
				rx={3}
				fill='#002649'
			/>
			<rect
				x={22}
				y={13}
				width={6}
				height={6}
				rx={3}
				fill='#002649'
			/>
			<rect
				x={4}
				y={13}
				width={6}
				height={6}
				rx={3}
				fill='#002649'
			/>
			<rect
				x={13}
				y={22}
				width={6}
				height={6}
				rx={3}
				fill='#002649'
			/>
		</svg>
	)
}

export default OpenMenu
