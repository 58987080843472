import styled, { css } from 'styled-components'

const ActionButton = styled.button<{ alertType: string }>`
	all: unset;
	font-family: var(--font-akzidenz);
	font-weight: 500;
	font-size: 16px;
	cursor: pointer;
	padding-inline: var(--spacer-2xs);
	height: 24px;
	line-height: 24px;
	border-radius: 4px;

	transition: background-color var(--animation-transition);

	color: var(--positive-green-600);
	&:hover {
		background-color: var(--positive-green-400-025);
	}

	&:focus-visible {
		outline: 2px solid var(--positive-green-400);
	}

	${(p) =>
		p.alertType === 'info' &&
		css`
			color: var(--baby-blue-800);

			&:hover {
				background-color: var(--baby-blue-400);
			}

			&:focus-visible {
				outline: 2px solid var(--baby-blue-800);
			}
		`}

	${(p) =>
		p.alertType === 'warning' &&
		css`
			color: var(--warning-yellow-800);

			&:hover {
				background-color: var(--warning-yellow-400-025);
			}

			&:focus-visible {
				outline: 2px solid var(--warning-yellow-400);
			}
		`}
	${(p) =>
		p.alertType === 'error' &&
		css`
			color: var(--danger-red-500);

			&:hover {
				background-color: var(--danger-red-500-025);
			}

			&:focus-visible {
				outline: 2px solid var(--danger-red-500);
			}
		`}
`

export default ActionButton
