import styled, { css } from 'styled-components'

import { media } from '../../utils/style-utils'

const shadedStyles = css`
	background-color: #f6f6f6;
	padding: 16px;
	font-size: 1rem;
	max-width: 76ch;
`

const CardStyles = css`
	-webkit-tap-highlight-color: transparent;
	padding: 16px 32px;
	box-shadow: 1px 8px 40px rgba(0, 0, 0, 0.15);
	background: #fff;
	margin-bottom: 30px;
	animation-delay: 1s;
	margin-top: 56px;

	${media.tablet`
		margin-top: 16px;
	`}
`

export const StyledRichTextContainer = styled.div<{
	shaded?: boolean
	isInCard?: boolean
	shouldAnimate?: boolean
}>`
	margin-bottom: 24px;

	${media.tablet`
		margin-bottom: 16px;
		word-break: break-word;
	`}

	& .embed {
		width: 100%;
		height: 100%;
		> div {
			height: 100%;
		}
		iframe {
			aspect-ratio: 16/9;
			width: 100%;
		}
	}

	${(p) => p.shaded && shadedStyles}
	.shaded {
		${shadedStyles}
	}

	${(p) => p.isInCard && CardStyles};

	.center-align {
		display: block;
		text-align: center;
	}

	.block-img {
		text-align: center;
		width: 100%;

		> img {
			max-width: 100%;
		}
	}
`

export const DecoratedHeadingWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`
