import React, { useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { map, some } from 'lodash'
import Link from 'next/link'

import { AutoSpace, media } from '../../utils/style-utils'
import NavBarButton from '../NavBarButton/NavBarButton'
import { BrandLogo, FullLogo } from '../icons'
import HamburgerButton from '../HamburgerButton/HamburgerButton'
import { HeaderNavigationDocument } from '../../../prismicio-types'
import { backgroundColors } from '../../styles/backgroundColors'

import MobileDropdownMenu from './MobileDropdownMenu'

interface IProps {
	navigation: HeaderNavigationDocument
}

const filterItems = (slices) =>
	map(slices, (slice) => ({
		label: slice.primary.label,
		link: slice.primary.link,
		isMobileNavbarItem: !!slice.primary.is_mobile_navbar_item,
		secondary: slice.primary.primary !== true,
	}))

const NavBar: React.FC<IProps> = ({ navigation }) => {
	const filteredItems = useMemo(() => filterItems(navigation.data.slices), [navigation.data.slices])
	const backgroundColor =
		navigation.data.background_color && backgroundColors(navigation.data.background_color ?? 'White')

	const logoColor = navigation.data.logo_color && backgroundColors(navigation.data.logo_color)
	const hasMobileItems = some(filteredItems, ['isMobileNavbarItem', false])

	const [menuStatus, setMenuStatus] = useState<'open' | 'closed'>('closed')

	return (
		<NavBarContainer>
			<NavBarInner backgroundColor={backgroundColor}>
				<StyledLink href='/'>
					<FullLogo
						size={20}
						fill={logoColor ?? undefined}
					/>
				</StyledLink>
				<MobileLogoWrapper href='/'>
					<BrandLogo
						size={10}
						fill={logoColor ?? undefined}
					/>
				</MobileLogoWrapper>
				<ItemsContainer>
					{map(filteredItems, (item, index) => {
						return (
							<Link
								key={index}
								href={item.link.url}
								legacyBehavior
								passHref
							>
								<Button
									key={`button-${item.label}`}
									isMobileNavbarItem={item.isMobileNavbarItem}
									secondary={item.secondary}
								>
									{item.label}
								</Button>
							</Link>
						)
					})}
					{hasMobileItems && (
						<MenuWrapper>
							<HamburgerButton
								onClick={() => setMenuStatus((prev) => (prev === 'closed' ? 'open' : 'closed'))}
								isOpen={menuStatus === 'open'}
							/>
						</MenuWrapper>
					)}
				</ItemsContainer>
			</NavBarInner>
			<MobileDropdownMenu
				isOpen={menuStatus === 'open'}
				items={filteredItems}
			/>
		</NavBarContainer>
	)
}

export default NavBar

const AUTO_SPACE_MULTIPLIER = 2

const StyledLink = styled(Link)`
	display: flex;
	align-items: center;

	${media.tablet`
		display: none;
	`}

	&:focus-visible {
		outline: 3px solid var(--orange);
		border-radius: 46px;
		outline-offset: 8px;
	}

	transition: all 0.25s ease-in-out;
`

const NavBarInner = styled.div<{ backgroundColor?: string | null }>`
	background-color: ${({ backgroundColor }) => backgroundColor ?? 'white'};
	border-radius: 56px;
	width: 92vw;
	display: flex;
	align-items: strech;
	justify-content: space-between;
	height: 76px;
	padding: 0px 16px 0px 32px;
	z-index: 2; // * 2 because of the mobile dropdown animation

	${media.tabletSm`
		height: 64px;
		width: 88vw;
	`}

	${media.phoneXs`
		padding: 0px 16px 0px 20px;
	`}
`
const NavBarContainer = styled.div`
	z-index: 1001;
	position: absolute;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	margin-top: ${AutoSpace['desktop'] * AUTO_SPACE_MULTIPLIER}px;

	${media.desktopHuge`
		margin-top: ${AutoSpace['desktopHuge'] * AUTO_SPACE_MULTIPLIER}px;
	`}

	${media.tablet`
		margin-top: ${AutoSpace['tablet'] * AUTO_SPACE_MULTIPLIER}px;
	`}

	${media.tablet`
		margin-top: ${AutoSpace['phone'] * AUTO_SPACE_MULTIPLIER}px;
		gap: var(--spacer-2xs);
	`}
`

const ItemsContainer = styled.div`
	display: flex;
	align-items: center;
	gap: var(--spacer-2xs);
`

const MenuWrapper = styled.div`
	display: none;
	${media.tablet`
		display: block;
	`}
`

const Button = styled(NavBarButton)<{ isMobileNavbarItem?: boolean }>`
	${media.tablet`
		display: none;

		${(p) =>
			p.isMobileNavbarItem &&
			css`
				display: flex !important;
				align-items: center;
			`}
	`}
`

const MobileLogoWrapper = styled(Link)`
	display: none;
	margin-top: 27px;
	margin: 0px;

	${media.tablet`
		display: flex;
		align-items: center;
	`}
`
