import { AppProps } from 'next/dist/shared/lib/router/router'
import { Analytics } from '@vercel/analytics/react'
import { MotionConfig } from 'framer-motion'
import { QueryClient, QueryClientProvider } from 'react-query'
import { useRouter } from 'next/router'

import Layout from '../layout'
import GlobalStyle from '../styles/globalStyles'
import Toaster from '../providers/notifications/NotificationsProvider'
import CookieHubLoader from '../../scripts/CookieHub'
import SegmentScript from '../../scripts/Segment'

function MyApp({ Component, pageProps }: AppProps) {
	const { pathname } = useRouter()
	const queryClient = new QueryClient()

	return (
		<QueryClientProvider client={queryClient}>
			<GlobalStyle $slicemachine={pathname === '/slice-simulator'} />
			<CookieHubLoader />
			<SegmentScript />
			<Layout
				// navigation={pageProps.headerNavigation}
				{...pageProps.layoutData}
			>
				<MotionConfig reducedMotion='user'>
					<Component {...pageProps} />
				</MotionConfig>
				<Analytics />
			</Layout>

			<Toaster />
		</QueryClientProvider>
	)
}

export default MyApp
