import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

interface IHamburgerButton {
	onClick: Function
	isOpen: boolean
}

const ANIMATIONS = {
	hamburger: {
		initial: {
			rotate: 0,
		},
		start: {
			rotate: -90,
		},
		end: {
			rotate: -90,
		},
	},
	openMenu: {
		start: {
			rotate: 0,
		},
		end: {
			rotate: -90,
		},
	},
}

const HAMBURGER_CONSTANTS = {
	x: 4,
	width: 24,
	height: 4,
	rx: 2,
	fill: 'var(--blue-400)',
}

const OPEN_MENU_CONSTANTS = {
	width: 6,
	height: 6,
	rx: 3,
	fill: 'var(--deep-blue)',
}

const HamburgerButton: React.FC<IHamburgerButton> = ({ onClick, isOpen }) => {
	return (
		<Button
			onClick={() => onClick()}
			animate={isOpen ? 'open' : 'closed'}
		>
			<svg
				width='32'
				height='32'
				viewBox='0 0 32 32'
			>
				<motion.rect
					variants={{
						open: { ...OPEN_MENU_CONSTANTS, x: 13, y: 4 },
						closed: {
							...HAMBURGER_CONSTANTS,
							y: 5,
						},
					}}
				/>
				<motion.rect
					variants={{
						open: { ...OPEN_MENU_CONSTANTS, x: 22, y: 13 },
						closed: {
							...HAMBURGER_CONSTANTS,
							y: 14,
						},
					}}
				/>
				<motion.rect
					variants={{
						open: { ...OPEN_MENU_CONSTANTS, x: 4, y: 13, opacity: 1 },
						closed: {
							...HAMBURGER_CONSTANTS,
							y: 23,
							opacity: 0,
						},
					}}
					transition={{ duration: 0.1 }}
				/>
				<motion.rect
					variants={{
						open: { ...OPEN_MENU_CONSTANTS, x: 13, y: 22 },
						closed: {
							...HAMBURGER_CONSTANTS,
							y: 23,
						},
					}}
				/>
			</svg>
		</Button>
	)
}

export default HamburgerButton

const Button = styled(motion.button)`
	all: unset;
	display: flex;
	align-items: center;
	cursor: pointer;

	-webkit-tap-highlight-color: transparent;
`

const ButtonContent = styled.div``
