import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { RichTextField } from '@prismicio/client'
import { RichTextBlock } from 'prismic-reactjs'

import { Info } from '../icons'
import XMark from '../icons/XMark'
import { media } from '../../utils/style-utils'
import RichText from '../RichText'

interface Props {
	title: string
	message?: string | RichTextField | RichTextBlock[]
	type?: 'info' | 'warning'
	index: number
	visible: boolean
	onClose: () => void
}
const HeaderNotification: React.FC<Props> = ({ title, message, type = 'info', visible, onClose, index }) => {
	const iconsColor = type === 'info' ? 'var(--baby-blue-800)' : 'var(--danger-red-500)'
	const first = index === 0
	return (
		<NotificationWrapper
			visible={visible}
			type={type}
			isFirst={first}
		>
			<LeftWrapper>
				<Info fill={iconsColor} />
				<ContentWrapper>
					<Title>{title}</Title>
					{message && (
						<Description>{typeof message === 'object' ? <StyledRichText content={message} /> : message}</Description>
					)}
				</ContentWrapper>
			</LeftWrapper>
			<MarkWrapper
				notificationType={type}
				onClick={onClose}
			>
				<XMark fill={iconsColor} />
			</MarkWrapper>
		</NotificationWrapper>
	)
}

const enterAnimation = keyframes`
  0% {transform: translate3d(0,${1 * -200}%,0); opacity:.5;}
  100% {transform: translate3d(0,0,0); opacity:1;}
`

const exitAnimation = keyframes`
  0% {transform: translate3d(0,0,-1px); opacity:1;}
  100% {transform: translate3d(0,${1 * -150}%,-1px); opacity:0;}
`

const StyledRichText = styled(RichText)`
	p {
		line-height: 1;
		margin: unset;
	}

	a {
		text-decoration: underline;
		font-weight: 500;
	}
`

const NotificationWrapper = styled.div<{ type: 'info' | 'warning'; isFirst: boolean; visible: boolean }>`
	display: flex !important;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	border-radius: 56px;
	padding: 24px;
	width: 96%;
	margin-top: 10px;

	background-color: ${(prop) => (prop.type === 'info' ? 'var(--baby-blue-100)' : 'var(--danger-red-100)')};

	${media.tablet`
    ${(prop) => prop.isFirst && 'margin-top: -20px'};
    `}

	${media.phone`
    align-items: flex-start;
    padding: 16px;

    `}

	animation: ${(p) =>
		p.visible
			? css`
					${enterAnimation} 0.35s cubic-bezier(.21,1.02,.73,1) forwards
			  `
			: css`
					${exitAnimation} 0.8s forwards cubic-bezier(.06,.71,.55,1)
			  `};
`

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 16px;
	align-items: center;

	${media.phone`
flex-direction: column;
gap: 0;
`}
`

const Title = styled.p`
	font-weight: 500;
	margin: 0;
`

const Description = styled.p`
	font-weight: 400;
	margin: 0;
`

const LeftWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 23px;

	${media.phone`
    gap: 16px;`}
`

const MarkWrapper = styled.button<{ notificationType: 'info' | 'warning' }>`
	display: flex;
	margin: 4px;
	background-color: transparent;
	border: none;
	cursor: pointer;
`

export default HeaderNotification
