import { LinkToMediaField } from '@prismicio/client'
import React from 'react'
import Lottie from 'react-lottie'
import styled from 'styled-components'

import { getLinkUrl } from '../../utils/ts-helpers'

interface ILottieCard {
	media: LinkToMediaField
	animateOnHover?: boolean
}

const LottieCard: React.FC<ILottieCard> = ({ media, animateOnHover }) => {
	const ref = React.useRef(null)
	const defaultOptions = {
		loop: true,
		autoplay: animateOnHover ? false : true,
		path: getLinkUrl(media),
	}

	//@ts-ignore
	const handleClickToPause = () => ref?.current?.handleClickToPause?.()

	return (
		<ImageWrapper
			onMouseEnter={animateOnHover ? handleClickToPause : undefined}
			onMouseLeave={animateOnHover ? handleClickToPause : undefined}
		>
			<Lottie
				ref={ref}
				options={defaultOptions}
			/>
		</ImageWrapper>
	)
}

const ImageWrapper = styled.div`
	position: relative;
	height: 100%;
	width: 100%;
`

export default LottieCard
