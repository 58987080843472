import * as React from 'react'

import { KvikaSVGProps } from './types'

const Arrow = ({ height = 22, fill = 'var(--orange)', width = 20, rotate = 0 }: KvikaSVGProps) => (
	<svg
		width={width}
		height={height}
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 22 20'
		style={{ transform: `rotate(${rotate}deg)` }}
	>
		<path
			d='M21.0593 11.0594C21.6453 10.4734 21.6453 9.52188 21.0593 8.93594L13.5593 1.43594C12.9734 0.850006 12.0218 0.850006 11.4359 1.43594C10.85 2.02188 10.85 2.97344 11.4359 3.55938L16.3812 8.50001L1.99995 8.5C1.17026 8.5 0.49995 9.17032 0.49995 10C0.49995 10.8297 1.17026 11.5 1.99995 11.5L16.3812 11.5L11.4406 16.4406C10.8546 17.0266 10.8546 17.9781 11.4406 18.5641C12.0265 19.15 12.9781 19.15 13.564 18.5641L21.064 11.0641L21.0593 11.0594Z'
			fill={fill}
		/>
	</svg>
)

export default Arrow
