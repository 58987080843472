import { css } from 'styled-components'

export const media = {
	phoneXs: (...args) => {
		return css`
			@media screen and (max-width: 374px) {
				${css(...args)};
			}
		`
	},
	phone: (...args) => {
		return css`
			@media screen and (max-width: 430px) {
				${css(...args)};
			}
		`
	},
	tabletSm: (...args) => {
		return css`
			@media screen and (max-width: 592px) {
				${css(...args)};
			}
		`
	},
	tabletMd: (...args) => {
		return css`
			@media screen and (max-width: 700px) {
				${css(...args)};
			}
		`
	},
	tablet: (...args) => {
		return css`
			@media screen and (max-width: 1023px) {
				${css(...args)};
			}
		`
	},
	// desktopMinPlus: (...args) => {
	// 	return css`
	// 		@media screen and (max-width: 1024px) {
	// 			${css(...args)};
	// 		}
	// 	`
	// },
	desktopSm: (...args) => {
		return css`
			@media screen and (max-width: 1359px) {
				${css(...args)};
			}
		`
	},
	desktopMd: (...args) => {
		return css`
			@media screen and (max-width: 1512px) {
				${css(...args)};
			}
		`
	},

	desktopLg: (...args) => {
		return css`
			@media screen and (min-width: 1513px) {
				${css(...args)};
			}
		`
	},
	desktopXl: (...args) => {
		return css`
			@media screen and (min-width: 2128px) {
				${css(...args)};
			}
		`
	},
	desktopHuge: (...args) => {
		return css`
			@media screen and (min-width: 3000px) {
				${css(...args)};
			}
		`
	}
}

export const devices = {
	ios: (args) => {
		return css`
			@supports (-webkit-text-size-adjust: none) {
				${css(args)};
			}
		`
	},
	safariDesktop: (...args) => {
		return css`
			@supports (-webkit-hyphens: none) {
					${css(...args)};
				}
		`
	},
	desktopHuge: (...args) => {
		return css`
			@media screen and (min-width: 3000px) {
				${css(...args)};
			}
		`
	}
}

export const AutoSpace = {
	desktopHuge: 40,
	desktop: 24,
	tabletLg: 16,
	tablet: 8,
	phone: 8,
}
