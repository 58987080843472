import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { PrismicDocument } from '@prismicio/types'
import { RichTextBlock } from 'prismic-reactjs'
import { ImageField, LinkField } from '@prismicio/client'

import Zendesk, { ZendeskAPI } from '../Zendesk/Zendesk'
import { easings } from '../../styles/globalStyles'
import { media } from '../../utils/style-utils'
import RichText from '../RichText'
import { getLinkUrl } from '../../utils/ts-helpers'
import { BackgroundColorKey, backgroundColors } from '../../styles/backgroundColors'

export interface IChat extends PrismicDocument {
	type: 'supportBubble'
	data: {
		heading: string
		openingIcon: ImageField
		closingIcon: ImageField
		description: RichTextBlock[]
		background_color: BackgroundColorKey
		hover_background_color: BackgroundColorKey
		rows: {
			icon: ImageField
			isChatButton: boolean
			label: string
			link: LinkField
		}[]
	}
}

const Chat = ({ supportBubble }: { supportBubble: IChat }) => {
	const [isSupportBubbleActive, setSupportBubbleActive] = useState<boolean>(false)
	const [isChatOpen, setIsChatOpen] = useState(false)
	const [isChatSettingsReady, setIsChatSettingsReady] = useState(false)
	const router = useRouter()

	const setIsChatToggle = () => {
		setIsChatOpen(!isChatOpen)
		setSupportBubbleActive(false)
	}

	useEffect(() => {
		if (isChatSettingsReady) {
			ZendeskAPI('webWidget', 'setLocale', router.locale)

			ZendeskAPI('webWidget:on', 'close', () => {
				setIsChatOpen(false)
				ZendeskAPI('webWidget', 'hide')
			})
		}
	}, [isChatSettingsReady, router.locale])

	useEffect(() => {
		if (!isChatSettingsReady) {
			return
		}

		if (!isChatOpen) {
			ZendeskAPI('webWidget', 'hide')
		}

		if (isChatOpen) {
			ZendeskAPI('webWidget', 'show')
			ZendeskAPI('webWidget', 'toggle')
		}
	}, [isChatSettingsReady, isChatOpen])

	return (
		<StyledChat>
			<StyledContainer>
				<StyledButton
					isActive={isSupportBubbleActive}
					onClick={() => setSupportBubbleActive(!isSupportBubbleActive)}
					background={supportBubble.data.background_color || 'White'}
					hoverBackground={supportBubble.data.hover_background_color || 'Baby Blue'}
				>
					<StyledPlusIcon
						src={supportBubble.data.closingIcon?.url ?? ''}
						alt={supportBubble.data.closingIcon?.alt ?? ''}
						isActive={isSupportBubbleActive}
					/>
					<StyledChatIcon
						alt={supportBubble.data.openingIcon?.alt ?? ''}
						src={supportBubble.data.openingIcon?.url ?? ''}
						isActive={isSupportBubbleActive}
					/>
				</StyledButton>
				<StyledModal isActive={isSupportBubbleActive}>
					<StyledModalText>
						<h4>{supportBubble.data.heading}</h4>
						<StyledRichText content={supportBubble?.data.description} />
					</StyledModalText>
					<StyledUnorderedLists>
						{supportBubble?.data.rows?.map((row, index) => {
							return (
								<li key={index}>
									{row?.isChatButton ? (
										<button onClick={setIsChatToggle}>
											<img
												alt={row.icon.alt ?? ''}
												src={row.icon.url ?? ''}
											/>
											{row?.label}
										</button>
									) : (
										<Link href={getLinkUrl(row.link)}>
											<img
												alt={row.icon.alt ?? ''}
												src={row.icon.url ?? ''}
											/>
											{row?.label}
										</Link>
									)}
								</li>
							)
						})}
						<Zendesk
							defer
							zendeskKey='a228147a-1c5d-4932-8b87-b97b1aa8f65e'
							{...zendesk_settings}
							onLoaded={() => setIsChatSettingsReady(true)}
						/>
					</StyledUnorderedLists>
				</StyledModal>
			</StyledContainer>
		</StyledChat>
	)
}

const zendesk_settings = {
	launcher: {
		chatLabel: {
			'en-US': 'Need Help',
			'is-IS': 'Netspjall',
		},
	},
	chat: {
		departments: {
			enabled: [],
		},
	},
}

const StyledChat = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 3;
	pointer-events: none;
`

const StyledContainer = styled.div`
	height: 100%;
	max-height: 56.25rem;
	transition: max-height 0.765s ${easings.cubicInOut};
`

const StyledButton = styled.button<{
	isActive: boolean
	background: BackgroundColorKey
	hoverBackground: BackgroundColorKey
}>`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	position: absolute;
	right: 4.8125rem;
	padding: 0;
	bottom: 1.5625rem;
	z-index: 2;
	width: 70px;
	height: 70px;
	margin: 0;
	border-radius: 100%;
	font-size: 0;
	pointer-events: all;
	background: ${(props) =>
		props.isActive ? backgroundColors(props.hoverBackground) : backgroundColors(props.background)};
	border: none;

	&:hover {
		transform: scale(1.12);
		background: ${(props) => backgroundColors(props.hoverBackground)};
	}

	img {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 2;
		transition: transform 0.543s ${easings.cubicInOut};
	}

	${media.phone`
		right: 1.8125rem;
	`};
`

const StyledChatIcon = styled.img<{ isActive: boolean }>`
	transform: translate(-50%, -50%) scale(${(props) => (props.isActive ? 0 : 1)});
`

const StyledPlusIcon = styled.img<{ isActive: boolean }>`
	transform: translate(-50%, -50%) rotate(-45deg) scale(${(props) => (props.isActive ? 1 : 0)});
`

const StyledModal = styled.div<{ isActive: boolean }>`
	position: absolute;
	right: 4.69rem;
	bottom: 7.8125rem;
	z-index: 1;
	transform: scale(${(props) => (props.isActive ? 1 : 0)});
	transform-origin: 14.6875rem calc(100% + 3.4375rem);
	width: 16.875rem;
	background: var(--classic-white);
	border-radius: 0.75rem;
	opacity: ${(props) => (props.isActive ? 1 : 0)};
	visibility: ${(props) => (props.isActive ? 'visible' : 'hidden')};
	font-weight: 400;
	pointer-events: all;
	transition: transform 0.3s ${easings.expoInOut}, opacity 0.3s ${easings.expoInOut}, visibility 0.3s;

	${media.phone`
		right: 1.6125rem;
	`};
`

const StyledModalText = styled.div`
	padding: 1.4375rem 1.875rem 1.875rem;

	h4 {
		margin-top: 0;
		margin-bottom: 20px;
		color: var(--deep-blue);
		font-size: 1.3rem;
		line-height: 1.3rem;
	}
`
const StyledRichText = styled(RichText)`
	p {
		margin: 0;
		font-family: var(--font-atyp-display);
		font-size: 0.875rem;
		line-height: 1rem;
	}
`
const StyledUnorderedLists = styled.ul`
	display: flex;
	flex-flow: column nowrap;
	position: relative;
	z-index: 2;
	list-style-type: none;
	padding-left: 0;
	margin: 0;
	border-radius: 0.75rem;

	a,
	button {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		gap: 1.25rem;
		width: 100%;
		padding: 1rem 1.875rem;
		background-color: var(--classic-white);
		color: var(--deep-blue);
		border: none;
		position: relative;
		cursor: pointer;
		border-top: 1px solid var(--baby-blue-400);
		z-index: 2;
		font-size: 1rem;
		line-height: 162.5%;

		font-weight: 400;
		transition: background 0.543s, border 0.543s, color 0.543s;

		&:hover {
			background: var(--baby-blue-400);
			border-color: var(--classic-white);
		}

		img {
			pointer-events: none;
		}

		img {
			width: 20px;
			height: 20px;
		}
	}
	li:last-child {
		a,
		button {
			border-radius: 0 0 0.75rem 0.75rem;

			&::after {
				content: '';
				position: absolute;
				bottom: -1rem;
				right: 0.9375rem;
				z-index: 1;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 1.6875rem 1.375rem 0;
				border-color: var(--classic-white) transparent transparent transparent;
				transition: border 0.543s;
			}

			&:hover::after {
				border-top-color: var(--baby-blue-400);
			}
		}
	}
`

export default Chat
