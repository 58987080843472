import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../utils/style-utils'

interface INavBarButton {
	children: React.ReactNode
	secondary?: boolean
	className?: string
}

const NavBarButton: React.FC<INavBarButton> = ({ children, secondary = false, className, ...rest }) => {
	return (
		<Button
			className={className}
			secondary={secondary}
			{...rest}
		>
			{children}
		</Button>
	)
}

export default NavBarButton

const Button = styled.a<{ secondary?: boolean }>`
	display: flex;
	align-items: center;

	border: none;
	height: 48px;
	background-color: var(--deep-blue);
	color: var(--neon);
	border-radius: 32px;
	padding-inline: 40px;
	font-weight: 500;
	font-family: var(--font-atyp-display);
	cursor: pointer;
	font-size: 1.25rem;

	&:hover,
	:focus {
		background-color: var(--blue-700);
	}

	&:focus-visible {
		outline: 3px solid var(--orange);
		outline-offset: 1px;
	}

	${media.tablet`
		font-size: 1rem;
		line-height: 1.5;
		width: max-content;
	`};

	${media.tabletSm`
		font-size: 1rem;
		padding-inline: 40px;
	`}

	${media.phone`
		width: 100%;
	`}

	${(p) =>
		p.secondary &&
		css`
			padding: 15px 24px 15px 18px;
			background-color: transparent;
			font-family: var(--font-atyp-display);
			transition: all 0.25s ease-in-out;
			color: var(--deep-blue);

			&:hover,
			&:focus {
				background-color: #eff2f5;
				&:before {
					margin-right: 14px;
					margin-left: 0px;
					opacity: 1;
				}
			}

			&:before {
				content: '';
				margin-right: 0px;
				margin-left: 14px;
				border-radius: 12px;
				width: 6px;
				height: 6px;
				background: #002649;
				opacity: 0;
				transition: all 0.25s ease-in-out;
			}

			&:before {
				content: '';
				margin-right: 0px;
				margin-left: 14px;
				border-radius: 12px;
				width: 6px;
				height: 6px;
				background: #002649;
				opacity: 0;
				transition: all 0.25s ease-in-out;
			}
		`};
`
