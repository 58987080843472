// Note do NOT export or use this enum directly!!
// instead you should use the backgroundColors methods exported below.
// This ensures we have type safety and get an error if we ar using invalid values to index it
enum BackgroundColors {
	'Neon' = 'var(--neon)',
	'Blue (100)' = 'var(--blue-100)',
	'Baby Blue (200)' = 'var(--baby-blue-200)',
	'Baby Blue' = 'var(--baby-blue-400)',
	'Orange' = 'var(--orange)',
	'Deep Blue' = 'var(--deep-blue)',
	'Transparent' = 'transparent',
	'White' = 'var(--classic-white)',
}

export type BackgroundColorKey = keyof typeof BackgroundColors

export const backgroundColors = (accessor: BackgroundColorKey) => BackgroundColors[accessor]
