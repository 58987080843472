import { Element } from '@prismicio/helpers'
import styled from 'styled-components'
import { PrismicImage } from '@prismicio/react'

import { DecoratedHeadingWrapper } from './rich-text.styles'

// * Headings supported by design system typography / prismic
const SUPPORTED_HEADINGS = ['heading1', 'heading2', 'heading3', 'heading4']

export interface SerializerOptions {
	enableMediaLinks?: boolean
	enableFileIconAnchors?: boolean
}

const DEFAULT_OPTIONS: SerializerOptions = {
	enableMediaLinks: true,
	enableFileIconAnchors: true,
}

const HtmlSerializer = (
	type,
	element,
	content,
	children,
	key,
	options: SerializerOptions = {
		...DEFAULT_OPTIONS,
	}
) => {
	const serializerOptions = {
		...DEFAULT_OPTIONS,
		...options,
	}

	if (SUPPORTED_HEADINGS.includes(element.type) && element?.spans[0]?.data?.label === 'decorated-heading') {
		const HeadingType = `h${element.type.substr(-1)}` as keyof JSX.IntrinsicElements

		return (
			<DecoratedHeadingWrapper key={key}>
				<HeadingType>{children}</HeadingType>
			</DecoratedHeadingWrapper>
		)
	}

	if (type === Element.image) {
		return (
			<BorderRadiusImage
				src={element.url}
				alt={element.alt}
			/>
		)
	}

	return null
}

const BorderRadiusImage = styled.img`
	width: 100%;
	border-radius: 56px;
`

export default HtmlSerializer
