import { KvikaSVGProps } from './types'
const RATIO = 30 / 8

interface IBrandLogoProps extends KvikaSVGProps {
	size: number
}

const BrandLogo = ({ fill = '#DAFDA3', size = 30 }: IBrandLogoProps) => {
	return (
		<svg
			width={size ? RATIO * size : 30}
			height={size}
			fill={fill}
			viewBox='0 0 30 8'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path d='M25.977 7.84379C28.0262 7.84379 29.6874 6.18256 29.6874 4.13332C29.6874 2.08409 28.0262 0.422852 25.977 0.422852C23.9277 0.422852 22.2665 2.08409 22.2665 4.13332C22.2665 6.18256 23.9277 7.84379 25.977 7.84379Z' />
			<path d='M14.8452 7.84379C16.8945 7.84379 18.5557 6.18256 18.5557 4.13332C18.5557 2.08409 16.8945 0.422852 14.8452 0.422852C12.796 0.422852 11.1348 2.08409 11.1348 4.13332C11.1348 6.18256 12.796 7.84379 14.8452 7.84379Z' />
			<path d='M3.71047 7.84379C5.75971 7.84379 7.42094 6.18256 7.42094 4.13332C7.42094 2.08409 5.75971 0.422852 3.71047 0.422852C1.66124 0.422852 0 2.08409 0 4.13332C0 6.18256 1.66124 7.84379 3.71047 7.84379Z' />
			<path d='M25.9774 3.31177H3.71289V4.95448H25.9774V3.31177Z' />
		</svg>
	)
}

export default BrandLogo
