const FullLogoSecondary = (props) => (
	<svg
		width={218}
		height={64}
		viewBox='0 0 218 64'
		fill='#DAFDA3'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='M0.00370736 28.4601L5.51859 27.3045C6.00749 30.175 7.96677 31.6084 11.4001 31.6084C13.1483 31.6084 14.5594 31.2676 15.6261 30.5861C16.6928 29.9046 17.2261 29.0379 17.2261 27.986C17.2261 26.8675 16.6669 26.0156 15.5446 25.4378C14.4224 24.86 13.0594 24.4414 11.4483 24.1785C9.83714 23.9155 8.21861 23.5933 6.59266 23.2081C4.96672 22.8229 3.59263 22.0636 2.47039 20.9228C1.34816 19.7857 0.7889 18.2523 0.7889 16.33C0.7889 13.9855 1.79633 12.041 3.80746 10.5002C5.8186 8.95936 8.34825 8.18896 11.3927 8.18896C14.4372 8.18896 16.9335 8.87047 18.8743 10.2372C20.8188 11.6039 21.9817 13.4595 22.3669 15.8041L16.8558 16.9597C16.4372 14.0892 14.615 12.6558 11.3964 12.6558C9.92605 12.6558 8.71861 12.9706 7.77416 13.6003C6.8297 14.2299 6.35562 15.0522 6.35562 16.067C6.35562 16.9782 6.75933 17.7116 7.56304 18.2708C8.36676 18.8301 9.38159 19.1968 10.6075 19.3746C11.8335 19.5487 13.1557 19.8116 14.5705 20.1635C15.9891 20.5154 17.3076 20.908 18.5335 21.345C19.7595 21.7821 20.7743 22.5599 21.578 23.6822C22.3817 24.8044 22.7855 26.2008 22.7855 27.8823C22.7855 30.2269 21.6928 32.1788 19.5039 33.7381C17.315 35.2974 14.6113 36.0752 11.3927 36.0752C8.17417 36.0752 5.61859 35.3937 3.62227 34.027C1.62596 32.6603 0.418523 30.8084 0 28.4601H0.00370736Z'
			fill={props.fill}
		/>
		<path
			d='M30.368 13.6005H24.6458V8.71885H30.368V0H35.8792V8.71885H43.4386V13.6005H35.8792V25.9936C35.8792 27.6381 36.2459 28.8307 36.9829 29.5641C37.7163 30.3012 38.8904 30.6679 40.5015 30.6679H43.4423V35.5495H40.5015C37.0348 35.5495 34.4792 34.7458 32.8348 33.1346C31.1903 31.5234 30.368 29.0567 30.368 25.7306V13.6005Z'
			fill={props.fill}
		/>
		<path
			d='M54.0606 8.71904V15.8082C54.9347 12.6229 56.3088 10.471 58.1829 9.34869C60.0533 8.23013 62.1829 7.94864 64.5607 8.50792V13.76C61.6533 12.9562 59.1792 13.2081 57.131 14.523C55.0828 15.8341 54.0606 17.7861 54.0606 20.3787V35.5534H48.5494V8.71904H54.0606Z'
			fill={props.fill}
		/>
		<path
			d='M78.1752 8.19287C81.3938 8.19287 84.0197 9.03364 86.0494 10.7115C88.079 12.393 89.0939 14.8079 89.0939 17.9562V35.5458H83.5827V28.4048C81.9382 33.5161 78.6308 36.0717 73.6604 36.0717C71.3492 36.0717 69.4233 35.3791 67.8862 33.9976C66.3454 32.616 65.5751 30.8049 65.5751 28.564C65.5751 25.938 66.501 23.9713 68.3566 22.6564C70.2122 21.3452 72.5752 20.5304 75.4456 20.2156L80.6419 19.6896C82.6012 19.5155 83.5827 18.6414 83.5827 17.0636C83.5827 15.7672 83.0827 14.7376 82.0864 13.9672C81.0901 13.1968 79.7827 12.8116 78.1752 12.8116C76.5678 12.8116 75.1381 13.2301 74.0011 14.0709C72.864 14.9117 72.1381 16.1376 71.8233 17.7451L66.364 16.4339C66.8529 13.9153 68.1677 11.9115 70.3011 10.4226C72.4344 8.93364 75.0604 8.19287 78.1752 8.19287ZM75.4456 31.6123C77.7197 31.6123 79.6456 30.8493 81.2197 29.327C82.7938 27.8047 83.5827 26.0269 83.5827 23.9972V22.5268C83.1642 23.2268 82.1456 23.6638 80.5382 23.8379L75.4456 24.4676C74.1159 24.6417 73.0566 25.038 72.2677 25.6491C71.4788 26.2602 71.0862 27.0936 71.0862 28.1418C71.0862 29.19 71.4788 30.0308 72.2677 30.6604C73.0566 31.2901 74.1122 31.6049 75.4456 31.6049V31.6123Z'
			fill={props.fill}
		/>
		<path
			d='M94.6575 24.4157V8.71875H100.169V24.4194C100.169 26.3787 100.798 27.9639 102.058 29.1714C103.317 30.3788 104.928 30.9826 106.887 30.9826C108.847 30.9826 110.406 30.3788 111.665 29.1714C112.924 27.9639 113.554 26.3787 113.554 24.4194V8.71875H119.069V24.4194C119.069 27.8491 117.921 30.6492 115.628 32.8197C113.336 34.9901 110.421 36.0754 106.887 36.0754C103.354 36.0754 100.383 34.9901 98.0946 32.8197C95.802 30.6492 94.6538 27.8491 94.6538 24.4194L94.6575 24.4157Z'
			fill={props.fill}
		/>
		<path
			d='M156.762 8.19287C159.525 8.19287 161.791 9.25958 163.562 11.3967C165.328 13.5338 166.214 16.245 166.214 19.534V35.5495H160.702V20.3748C160.702 18.3081 160.132 16.6043 158.995 15.2561C157.858 13.9079 156.447 13.2338 154.769 13.2338C152.88 13.2338 151.328 13.9079 150.121 15.2561C148.913 16.6043 148.31 18.3118 148.31 20.3748V35.5495H142.798V20.3748C142.798 18.3081 142.239 16.6043 141.117 15.2561C139.995 13.9079 138.598 13.2338 136.917 13.2338C135.028 13.2338 133.476 13.9079 132.269 15.2561C131.061 16.6043 130.458 18.3118 130.458 20.3748V35.5495H124.946V8.71882H130.458V15.282C131.191 13.0782 132.302 11.3448 133.791 10.0855C135.28 8.82623 137.021 8.19658 139.013 8.19658C141.147 8.19658 142.995 8.87067 144.554 10.2189C146.11 11.5671 147.206 13.3783 147.836 15.6524C148.465 13.3783 149.576 11.5671 151.169 10.2189C152.762 8.87067 154.625 8.19658 156.762 8.19658V8.19287Z'
			fill={props.fill}
		/>
		<path
			d='M171.651 24.4157V8.71875H177.162V24.4194C177.162 26.3787 177.791 27.9639 179.051 29.1714C180.31 30.3788 181.921 30.9826 183.88 30.9826C185.84 30.9826 187.399 30.3788 188.658 29.1714C189.918 27.9639 190.547 26.3787 190.547 24.4194V8.71875H196.062V24.4194C196.062 27.8491 194.914 30.6492 192.621 32.8197C190.329 34.9901 187.414 36.0754 183.88 36.0754C180.347 36.0754 177.377 34.9901 175.088 32.8197C172.795 30.6492 171.647 27.8491 171.647 24.4194L171.651 24.4157Z'
			fill={props.fill}
		/>
		<path
			d='M207.5 8.71904V15.8082C208.374 12.6229 209.748 10.471 211.622 9.34869C213.493 8.23013 215.622 7.94864 218 8.50792V13.76C215.093 12.9562 212.618 13.2081 210.57 14.523C208.522 15.8341 207.5 17.7861 207.5 20.3787V35.5534H201.989V8.71904H207.5Z'
			fill={props.fill}
		/>
		<path
			d='M207.489 64C211.186 64 214.182 61.0035 214.182 57.3071C214.182 53.6107 211.186 50.6143 207.489 50.6143C203.793 50.6143 200.797 53.6107 200.797 57.3071C200.797 61.0035 203.793 64 207.489 64Z'
			fill={props.fill}
		/>
		<path
			d='M160.637 64C164.333 64 167.33 61.0035 167.33 57.3071C167.33 53.6107 164.333 50.6143 160.637 50.6143C156.941 50.6143 153.944 53.6107 153.944 57.3071C153.944 61.0035 156.941 64 160.637 64Z'
			fill={props.fill}
		/>
		<path
			d='M6.6956 64C10.3919 64 13.3883 61.0035 13.3883 57.3071C13.3883 53.6107 10.3919 50.6143 6.6956 50.6143C2.99934 50.6143 0.00292969 53.6107 0.00292969 57.3071C0.00292969 61.0035 2.99934 64 6.6956 64Z'
			fill={props.fill}
		/>
		<path
			d='M207.489 55.8254H6.69141V58.7885H207.489V55.8254Z'
			fill={props.fill}
		/>
	</svg>
)

export default FullLogoSecondary
