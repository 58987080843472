import { ImageField } from '@prismicio/client'
import Image from 'next/image'
import React from 'react'
import styled from 'styled-components'

interface IImageCard {
	image: ImageField
}

const ImageCard: React.FC<IImageCard> = ({ image }) => {
	return (
		<ImageWrapper>
			<CardImage
				fill
				src={image.url ?? ''}
				alt={image.alt ?? ''}
				quality='100'
			/>
		</ImageWrapper>
	)
}

export default ImageCard

const ImageWrapper = styled.div`
	position: relative;
	height: 100%;
	width: 100%;
`
const CardImage = styled(Image)`
	object-fit: cover;
	width: 100%;
	height: 100%;
`
