import { FilledContentRelationshipField, LinkField } from '@prismicio/client'

/**
 *  Generic TS function, Returns the property type of some ReactComponent or never if T is not either a function component or a class component
 *
 * 	Use case: const FooterPropType = $ElementProps<Footer>
 *
 * 	Original reference: https://react-typescript-cheatsheet.netlify.app/docs/advanced/patterns_by_usecase/
 */
export type $ElementProps<T> = T extends React.ComponentType<infer Props>
	? Props extends object
		? Props
		: never
	: never

/**
 * Generic TS function, produces the name of a variable, type, or member as the string constant with type checking
 *
 * Use case : nameof<ICurrencyRates>('general') -> "general"
 * Use case : nameof<ICurrencyRates>('wrongName') -> compile time error
 *
 * Original reference: https://stackoverflow.com/a/50470026
 */
export const nameof = <T>(name: keyof T) => name

export const checkLinkUrl = (link: LinkField): link is FilledContentRelationshipField => {
	return 'url' in link && link.url ? true : false
}

export const getLinkUrl = (link: LinkField): string => {
	// @ts-ignore
	return checkLinkUrl(link) ? link.url : '#'
}


export type DiscriminateDocumentByTypeLiteral<Union, Literal> = Union extends { type: Literal } ? Union : never; 