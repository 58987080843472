import * as React from 'react'

import { KvikaSVGProps } from './types'

const MemoCircleInfoRegular = ({ fill = 'var(--baby-blue-800)' }: KvikaSVGProps) => (
	<svg
		width={28}
		height={25}
		viewBox='0 0 28 25'
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
	>
		<g clipPath='url(#clip0_1_7437)'>
			<path
				xmlns='http://www.w3.org/2000/svg'
				d='M15.5556 2.33864H3.06736C2.68139 2.33864 2.28958 2.68748 2.28958 3.11818V21.8273C2.28958 22.256 2.68139 22.6068 3.06736 22.6068H13.8299C14.4132 23.5033 15.1618 24.2877 16.0271 24.9113C15.8764 24.9308 15.716 24.9455 15.5556 24.9455H3.11111C1.39271 24.9455 0 23.5471 0 21.8273V3.11818C0 1.39587 1.39271 0 3.11111 0H15.5556C17.2715 0 18.6667 1.39587 18.6667 3.11818V9.67611C17.8306 9.91484 17.0431 10.2754 16.3333 10.7431V3.07433C16.3333 2.68748 15.9833 2.29479 15.5556 2.29479V2.33864ZM4.62292 7.40568C4.62292 6.75768 5.18681 6.23636 5.78958 6.23636H12.7896C13.4799 6.23636 13.9563 6.75768 13.9563 7.40568C13.9563 8.05368 13.4799 8.575 12.7896 8.575H5.78958C5.18681 8.575 4.62292 8.05368 4.62292 7.40568ZM12.7896 10.9136C13.4799 10.9136 13.9563 11.435 13.9563 12.083C13.9563 12.731 13.4799 13.2523 12.7896 13.2523H5.78958C5.18681 13.2523 4.62292 12.731 4.62292 12.083C4.62292 11.435 5.18681 10.9136 5.78958 10.9136H12.7896ZM8.12292 15.5909C8.8132 15.5909 9.28958 16.1122 9.28958 16.7602C9.28958 17.4082 8.8132 17.9295 8.12292 17.9295H5.78958C5.18681 17.9295 4.62292 17.4082 4.62292 16.7602C4.62292 16.1122 5.18681 15.5909 5.78958 15.5909H8.12292ZM13.9563 17.9295C13.9563 14.0562 17.1354 10.9136 21 10.9136C24.8646 10.9136 28 14.0562 28 17.9295C28 21.8029 24.8646 24.9455 21 24.9455C17.1354 24.9455 13.9563 21.8029 13.9563 17.9295ZM21 15.5909C21.6465 15.5909 22.1667 15.0696 22.1667 14.4216C22.1667 13.7736 21.6465 13.2523 21 13.2523C20.3535 13.2523 19.8333 13.7736 19.8333 14.4216C19.8333 15.0696 20.3535 15.5909 21 15.5909ZM20.2222 18.7091V21.0477C19.7944 21.0477 19.4444 21.3985 19.4444 21.8273C19.4444 22.256 19.7944 22.6068 20.2222 22.6068H21.7778C22.2056 22.6068 22.5556 22.256 22.5556 21.8273C22.5556 21.3985 22.2056 21.0477 21.7778 21.0477V17.9295C21.7778 17.5008 21.4278 17.15 21 17.15H20.2222C19.7944 17.15 19.4444 17.5008 19.4444 17.9295C19.4444 18.3583 19.7944 18.7091 20.2222 18.7091Z'
				fill='#002649'
			/>
		</g>
		<defs>
			<clipPath id='clip0_1_7437'>
				<rect
					width={28}
					height={25}
					fill='white'
				/>
			</clipPath>
		</defs>
	</svg>
)

export default MemoCircleInfoRegular
