import { map } from 'lodash'
import { useEffect } from 'react'
import { useQuery } from 'react-query'

import { INotification, createAlert } from '../providers/notifications/NotificationsProvider'
import { NotificationDocument } from '../../prismicio-types'

type Notification = {
	primary: NotificationDocument[]
}

const NOTIFICATION_API = '/api/notification'
const FIVE_MINUTES_IN_MS = 5 * 60 * 1000

const fetchNotifications = async (): Promise<NotificationDocument[]> => {
	return fetch(NOTIFICATION_API).then((data) => {
		if (data.status !== 200) throw 'Generic error'
		return data.text().then((text) => {
			return JSON.parse(text)
		})
	})
}

export const useFetchNotifications = (options?: { staleTime?: number; cacheTime?: number } | undefined) => {
	return useQuery('notifications', () => fetchNotifications(), {
		staleTime: options?.staleTime || FIVE_MINUTES_IN_MS,
		cacheTime: options?.cacheTime || FIVE_MINUTES_IN_MS,
		notifyOnChangeProps: ['data'],
	})
}

// * This hook will fetch notifications and display them in the UI
export const useNotifications = () => {
	const { isSuccess, data } = useFetchNotifications()

	useEffect(() => {
		isSuccess &&
			data?.map((notification, i) => {
				const durationInSeconds = notification.data.duration ? notification.data.duration * 1000 : 0
				return createAlert(
					{
						title: notification.data.title ?? '',
						message: notification.data.message ?? '',
						type: notification.data.type,
						uid: notification.uid,
						isHeaderNotification: true,
						order: i,
					},
					{
						duration: notification.data.infinite_duration ? undefined : durationInSeconds,
					}
				)
			})
	}, [data])
}
