import styled, { css } from 'styled-components'

import { media } from '../../utils/style-utils'

const HalfGridStyles = css`
	display: grid;
	grid-template-columns: repeat(4, 1fr);

	${media.desktopLg`
		column-gap: 40px;
 	 `}

	${media.desktopMd`
		column-gap: 24px;
	`}

	${media.desktopSm`
		column-gap: 16px;
	`}

	${media.tablet`
		// Ath það á eftir að klára grid styles í desgininu fyrir tablet og minna
		margin: 0 8px;
	`}
`

// export const HalfGridLeftStyles = css`
// 	display: grid;
// 	grid-column: 1 / span 7;
// 	column-gap: ${DEFAULT_GRID_GAP}px;

// 	${media.desktopLg`
// 		grid-template-columns: auto repeat(6, ${DEFAULT_COL_WIDTH});
// 	`}

// 	${media.desktopSm`
// 		grid-template-columns: 24px repeat(6, 1fr);
// 	`}

// 	${media.phone`
// 		grid-template-columns: 8px repeat(6, 1fr);
// 		column-gap: ${DEFAULT_MOBILE_GRID_GAP}px;
// 	`}
// `
// export const HalfFullBleedGridRightStyles = css`
// 	display: grid;
// 	grid-column: 2 / span 6;
// 	grid-template-columns: repeat(6, 1fr);

// 	${media.desktopLg`
// 		grid-template-columns: auto repeat(6, ${DEFAULT_COL_WIDTH});
// 	`}

// 	${media.desktopSm`
// 		grid-template-columns: 24px repeat(6, 1fr);
// 	`}

// 	${media.phone`
// 		grid-template-columns: 8px repeat(6, 1fr);
// 		column-gap: ${DEFAULT_MOBILE_GRID_GAP}px;
// 	`}
// `

export const FullBleedGridStyles = css`
	display: grid;

	column-gap: 40px;
	grid-template-columns: 120px repeat(8, 1fr) 120px;

	${media.desktopMd`
		column-gap: 24px;
	`}

	${media.desktopSm`
		grid-template-columns: 80px repeat(8, 1fr) 80px;
		column-gap: 16px;
	`}

	${media.tablet`
		grid-template-columns: 80px repeat(8, 1fr) 80px;
		column-gap: 8px;
	`}


	${media.phone`
		grid-template-columns: 48px repeat(8, 1fr) 48px;
		column-gap: 8px;
	`}
`

// We should rename this to semthing. It is not really a card grid, just a grid with a bit of border.
// @STEINI IN REVIEW, any ideas?
export const FullScreenCardGridStyles = css`
	display: grid;

	${media.desktopLg`
		grid-template-columns: 40px repeat(8, 1fr) 40px;
		margin: 0 40px;
		column-gap: 40px;
  	`}
	${media.desktopMd`
	  	grid-template-columns: 72px repeat(8, 1fr) 72px;
		margin: 0 24px;
		column-gap: 24px;
	`}

	${media.desktopSm`
		margin: 0 24px;
		grid-template-columns: 40px repeat(8, 1fr) 40px;
		column-gap: 16px;
	`}

	${media.tablet`
		// Ath það á eftir að klára grid styles í desgininu fyrir tablet og minna
		grid-template-columns: 32px repeat(8, 1fr) 32px;
		column-gap: 8px;
		margin: 0 16px;
		`}


		${media.phone`
		// Ath það á eftir að klára grid styles í desgininu fyrir tablet og minna
		grid-template-columns: 32px repeat(8, 1fr) 32px;
		column-gap: 8px;
		margin: 0 8px;
	`}
`

export const CardGrid = styled.div<{ backgroundColor?: string }>`
	${FullScreenCardGridStyles}
`
export const FullBleedGrid = styled.div`
	${FullBleedGridStyles}
`
